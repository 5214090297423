import React from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';

import {
    InputLabel,
    Typography,
    TextField,
    Checkbox,
    Grid,
    Button,
    MenuItem,
    FormGroup,
    FormControlLabel,
    FormControl,
    Select,
    Box,
    makeStyles
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import axios from 'axios';
import edusim from '../config/edusim';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(0),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(0),
    },
    checkboxGrid: {
      border: `${fade(theme.palette.text.primary, 0.2)} 1px solid` ,
      //marginTop: "5px",
      padding: "10px",
      paddingTop: "40px"
    }
  }));



const AssignmentView = (props) => {
  const classes = useStyles();
  const editAssignmentVisible = props.editAssignmentVisible;
  const setEditAssignmentVisible = props.setEditAssignmentVisible;
  const section = props.section;
  const setNewAssignmentVisible = props.setNewAssignmentVisible;
  const updateAssignments = props.updateAssignments;
  const addAssignment = props.addAssignment;
  
  const propsUser = props.propsUser;
  const propsUpdateUser = props.propsUpdateUser;

  const isMounted = React.useRef(null);
  
  const [assignment, setAssignment] = React.useState(props.selectedAssignment);
  const [loading, setLoading] = React.useState(false);

  const [newAssignment, setNewAssignment] = React.useState({
    is_assessment: false,
    is_inclass: false,
    published: false,
    earned_points: []
  });

  //error handling states
  const [error, setError] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [errorMessages, setErrorMessages] = React.useState({});
     
  const handleAddAssignment = (e) => {
    console.log(propsUser);
    setNewAssignment(assignment=>({...assignment, validate:true, validateCallBack: (validAssignment)=>{
      if(!validAssignment){
        setError('Please fix the required information before proceeding.');
        setNewAssignment(assignment=>({...assignment, validateCallBack: null}));
      }else{
        _addAssignment();
      }
    }}));
  }

  const handleSaveAssignment = (e) => {
    console.log(propsUser);
    setAssignment(assignment=>({...assignment, validate:true, validateCallBack: (validAssignment)=>{
      if(!validAssignment){
        setError('Please fix the required information before proceeding.');
        setAssignment(assignment=>({...assignment, validateCallBack: null}));
      }else{
        _saveAssignment();
      }
    }}));
  }

  const _saveAssignment = () => {
    setLoading(true);
      axios.post(edusim.api_base_uri+"/api/instructors/sections/"+section._id+"/assignments/"+assignment._id, 
      {assignment: {...assignment}}, {
        headers: {
          'x-access-token': propsUser.token
        }
      }).then(res => {
        console.log(res);
        updateAssignments(assignment);
        setEditAssignmentVisible(false);
      }).catch(e => {
        if(e.response){
            if(isMounted.current){
                setLoading(false);
                //setNewAssignmentVisible(false);
            }
            if(e.response.status === 403){
                propsUpdateUser({});
            }
        }else{
            if(isMounted.current){
                setLoading(false);
                setError("Network connection might be lost");
            }                    
        }
      });
    
    
  }

  const _addAssignment = () => {
      setLoading(true);
      axios.post(edusim.api_base_uri+"/api/instructors/sections/"+section._id+"/assignments", 
      {assignment: {_sectionid: section._id, _subjectid: section._subjectid, ...newAssignment}}, {
        headers: {
          'x-access-token': propsUser.token
        }
      }).then(res => {
        console.log(res);
        let tempAssignment = {...newAssignment};
        tempAssignment._id = res.data;
        setAssignment(tempAssignment);
        addAssignment(tempAssignment);
        setLoading(false);
        setNewAssignmentVisible(false);
      }).catch(e => {
        if(e.response){
            if(isMounted.current){
                setLoading(false);
                setNewAssignmentVisible(false);
            }
            if(e.response.status === 403){
                propsUpdateUser({});
            }
        }else{
            if(isMounted.current){
                setLoading(false);
                setError("Network connection might be lost, ")
            }                    
        }
      });
    
  }

  const handleCancelAssignment = (e) =>{
      editAssignmentVisible?setEditAssignmentVisible(false):setNewAssignmentVisible(false);
  }

  const handleSelectCategory = (e) => {
    !assignment?
    setNewAssignment({...newAssignment, _categoryid: e.target.value!=="Unassigned"?e.target.value:null})
    :setAssignment({...assignment, _categoryid: e.target.value!=="Unassigned"?e.target.value:null});
  }
  
  const handleSelectInterval = (e) => {
    !assignment?
    setNewAssignment({...newAssignment, _intervalid: e.target.value!=="Unassigned"?e.target.value:null})
    :setAssignment({...assignment, _intervalid: e.target.value!=="Unassigned"?e.target.value:null});
  }

  const handleFlagChange = (e, flag) =>{
    !assignment?
      setNewAssignment({...newAssignment, [flag]: !newAssignment[flag]})
      :setAssignment({...assignment, [flag]:!assignment[flag]});
  }

  const handleTextChange = (e, fieldName) => {
    !assignment?
      setNewAssignment({...newAssignment, [fieldName]: e.target.value.trim()})
      :setAssignment({...assignment, [fieldName]: e.target.value.trim()});
  }

  const handleIntChange = (e, fieldName) => {
    let intValue = (e.target.value.trim()!=="")?parseInt(e.target.value.trim()):null;
    !assignment?
      setNewAssignment({...newAssignment, [fieldName]: intValue})
      :setAssignment({...assignment, [fieldName]: intValue});
  }


  React.useEffect(()=>{
    let validatedAssignment = assignment?assignment:newAssignment;
    
    const validateFields = () => {
      let assignmentValid = true;
      
      if(!validatedAssignment.name){
        assignmentValid = false;
        setErrors(errors=> ({...errors, 'name': true}));
        setErrorMessages(errorMessages => ({...errorMessages, 'name': 'Required!'}));
      }else if(validatedAssignment.name.length < 5){
        assignmentValid = false;
        setErrors(errors=> ({...errors, 'name': true}));
        setErrorMessages(errorMessages=> ({...errorMessages, 'name': 'Minimum of 5 characters is required'}));
      }else{
        cancelErrors('name');
      }

      if(!validatedAssignment.short_name){
        assignmentValid = false;
        setErrors(errors=> ({...errors, 'short_name': true}));
        setErrorMessages(errorMessages => ({...errorMessages, 'short_name': 'Required!'}));
      }else if(validatedAssignment.short_name.length < 2){
        assignmentValid = false;
        setErrors(errors=> ({...errors, 'short_name': true}));
        setErrorMessages(errorMessages=> ({...errorMessages, 'short_name': 'Minimum of 2 characters is required'}));
      }else{
        cancelErrors('short_name');
      }
      
      if(!validPositiveInt(validatedAssignment.points, false) ||
        ((validatedAssignment.points<=0)&&(validatedAssignment.points!==null))){
        assignmentValid = false;
        setErrors(errors=> ({...errors, 'points': true}));
        setErrorMessages(errorMessages=> ({...errorMessages, 'points': 'A valid integer value greater than 0 is required.'}));
      }else{
        cancelErrors('points');
      }

      if(!validPositiveInt(validatedAssignment.bonus_points, false) ||
        ((validatedAssignment.bonus_points<0)&&(validatedAssignment.bonus_points!==null))){
        assignmentValid = false;
        setErrors(errors=> ({...errors, 'bonus_points': true}));
        setErrorMessages(errorMessages=> ({...errorMessages, 'bonus_points': 'A valid integer value 0 or more is required.'}));
      }else{
        cancelErrors('bonus_points');
      }

      if(validatedAssignment.due_date&&!validDate(validatedAssignment.due_date, false)){
        assignmentValid = false;
        setErrors(errors=>({...errors, 'due_date': true}));
        setErrorMessages(errorMessages=>({...errorMessages, 'due_date': 'ISO Date like 2022-12-22'}));
      }else{
        cancelErrors('due_date');
      }

      if(validatedAssignment.due_time&&!validTime(validatedAssignment.due_time, false)){
        assignmentValid = false;
        setErrors(errors=>({...errors, 'due_time': true}));
        setErrorMessages(errorMessages=>({...errorMessages, 'due_time': '24hr time, between 00:00 and 23:59'}));
      }else{
        cancelErrors('due_time');
      }

     if(validatedAssignment.validateCallBack)
          validatedAssignment.validateCallBack(assignmentValid);
    
    }

    const cancelErrors = (fieldName) => {
      setErrors(errors=>({...errors, [fieldName]: false}));
      setErrorMessages(errorMessages=>({...errorMessages, [fieldName]: null}));
    }

    const validDate = (date, required) => {
      //we are only interested in the first 10 charachters of this string
      if(!required && date.length===0) return true;
      let checkedDate = date.split("T")[0];
      if (moment(checkedDate?checkedDate:date, "YYYY-MM-DD", true).isValid()) {
        return true;
      } else {
        return false;
      }
    }
    
    const validTime = (time, required) =>{
      if(!required && time.length===0) return true;
      return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
    }

    const validPositiveInt = (num, required) =>{
      if(!required && num===null) return true;
      if(isNaN(num)) return false;
      return /^[0-9\b]+$/.test(num);
    }

    if(validatedAssignment.validate){
      validateFields();
    }
      
  },[assignment, newAssignment]);


  React.useEffect(()=>{
    setTimeout(()=>{
      if(error !== ''){
        setError('');
      }
    }, 3000);
  },[error]);


    return (
        <div>
          {(error !== '') &&
            <Alert style={{marginBottom: 10}} severity='error'>
                {error}
            </Alert>
          }
          <Typography 
            component="h2" 
            variant="h6" 
            color="primary" 
            gutterBottom>
              {editAssignmentVisible?'Edit ': 'Add '} Assignment
          </Typography>
            <Grid container spacing={3}> 
              <Grid item xs={6}>  
                  <TextField 
                    required
                    error={errors['name']? true: false}
                    helperText={errorMessages['name']? errorMessages['name']: null}
                    defaultValue= {editAssignmentVisible? assignment.name: ""}
                    onChange={(e) => handleTextChange(e, 'name')}
                    label="Assignment Name" 
                    variant="outlined" 
                    fullWidth
                    autoFocus>
                  </TextField>
              </Grid>
              <Grid item xs={6}>  
                  <TextField
                    required
                    error={errors['short_name']? true: false}
                    helperText={errorMessages['short_name']? errorMessages['short_name']: null}
                    defaultValue= {editAssignmentVisible? assignment.short_name: ""}
                    onChange={(e) => handleTextChange(e, 'short_name')} 
                    label="Assignment Short Name" 
                    variant="outlined" 
                    fullWidth>
                  </TextField>
              </Grid>
            </Grid>
{/* grid container for point and bonus points */}
            <Grid container spacing={3}> 
              <Grid item xs={6}>  
                  <TextField
                    error={errors['points']? true: false}
                    helperText={errorMessages['points']? errorMessages['points']: null}
                    defaultValue= {editAssignmentVisible? assignment.points: ""}
                    onChange={(e) => handleIntChange(e, 'points')}
                    label="Points" 
                    variant="outlined" 
                    fullWidth>
                  </TextField>
              </Grid>
              <Grid item xs={6}>
                  <TextField
                    error={errors['bonus_points']? true: false}
                    helperText={errorMessages['bonus_points']? errorMessages['bonus_points']: null}
                    defaultValue= {editAssignmentVisible? assignment.bonus_points: ""}
                    onChange={(e) => handleIntChange(e, 'bonus_points')}
                    label="Bonus Points" 
                    variant="outlined" 
                    fullWidth>
                  </TextField>
              </Grid>
            </Grid>        
{/* grid container for due date and due time */} 
            <Grid container spacing={3}> 
              <Grid item xs={6}>  
                  <TextField
                  error={errors['due_date']? true: false}
                  helperText={errorMessages['due_date']? errorMessages['due_date']: null}
                  defaultValue= {editAssignmentVisible? assignment.due_date: ""}
                  onChange={(e) => handleTextChange(e, 'due_date')}
                  //type="date" 
                  id="due-date" 
                  label="Due Date" 
                  variant="outlined"
                  fullWidth> 
                  </TextField>
              </Grid>
              <Grid item xs={6}>  
                  <TextField
                  error={errors['due_time']? true: false}
                  helperText={errorMessages['due_time']? errorMessages['due_time']: null}
                  defaultValue= {editAssignmentVisible? assignment.due_time: ""}
                  onChange={(e) => handleTextChange(e, 'due_time')}
                  //type="time" 
                  id="due-time" 
                  label="Due Time" 
                  variant="outlined" 
                  fullWidth>
                  </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={3}> 
              <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                  <InputLabel id="category-label">Category</InputLabel>
                  <Select
                  labelId='category-label'
                  label="Category"
                  defaultValue={editAssignmentVisible? props.selectedAssignment._categoryid?props.selectedAssignment._categoryid: "Unassigned":"Unassigned"} 
                  fullWidth
                  onChange = {(e)=>handleSelectCategory(e)}>
                      <MenuItem value="Unassigned">Unassigned</MenuItem>
                      {section.categoryTemplate.categories.map((cat, key)=>(
                        <MenuItem key={key} value={cat._id}>
                            {cat.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>  
                  <InputLabel id="interval-label">Interval</InputLabel>
                  <Select 
                  defaultValue= {editAssignmentVisible? props.selectedAssignment._intervalid?props.selectedAssignment._intervalid: "Unassigned":"Unassigned"}
                  labelId='interval-label'
                  label="Interval"
                  fullWidth
                  onChange = {(e)=>handleSelectInterval(e)}>
                      <MenuItem value="Unassigned">Unassigned</MenuItem>
                      {section.intervals.map((int, key)=>(
                          <MenuItem key={key} value={int._id}>
                              {int.name}
                          </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}> 
              <Grid item xs={12} sm={12} lg={6} xl={6}> 
                <TextField
                defaultValue= {editAssignmentVisible? assignment.instructions: ""} 
                id="outlined-basic" label="Instructions" variant="outlined" fullWidth
                onChange={(e) => handleTextChange(e, 'instructions')}> 
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={6} xl={6}>  
                <FormControl variant="outlined" fullWidth>
                <InputLabel id="flags-label">Flags</InputLabel>
                <Box labelId="flags-label" label="Flags" className={classes.checkboxGrid} borderRadius="borderRadius">

                <FormGroup>
                  <FormControlLabel 
                  control={
                    <Checkbox 
                    checked= {editAssignmentVisible? assignment.is_assessment: newAssignment.is_assessment} 
                    onChange={(e)=>handleFlagChange(e,"is_assessment")}
                    color="primary" 
                    />
                  } 
                  label= "Assessment"/>
                  <FormControlLabel 
                  control={
                    <Checkbox 
                    checked= {editAssignmentVisible? assignment.is_inclass: newAssignment.is_inclass} 
                    onChange={(e)=>handleFlagChange(e,"is_inclass")}
                    color="primary" 
                    />
                  } 
                  label= "In Class" />
                  <FormControlLabel 
                  control={
                    <Checkbox 
                    checked={editAssignmentVisible? assignment.published: newAssignment.published}  
                    onChange={(e)=>handleFlagChange(e,"published")}
                    color="primary" 
                    />
                  } 
                  label="Published"/>     
                </FormGroup>
                </Box>
                </FormControl>
              
              </Grid>
              
            </Grid>
            <Grid container spacing={3} style={{marginTop: 10}}                    
              direction="row"
              justify="flex-end"
              alignItems="center">
                <Grid item>
                  <Button onClick={(e)=>handleCancelAssignment(e)}>
                  Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={(e)=>editAssignmentVisible?handleSaveAssignment(e):handleAddAssignment(e)}> 
                    {editAssignmentVisible?'Save ': 'Add '} Assignment
                  </Button>
                </Grid>
            </Grid>
            </div>
        )
};
export default AssignmentView;