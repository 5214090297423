import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from "@reach/router";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import edusim from '../config/edusim';

import Alert from '@material-ui/lab/Alert';

import Box from '@material-ui/core/Box';

import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';

import sectionAvatarPlaceholder from '../imgs/sectionAvatarPlaceholder.png';

import data from '../config/data';
import { Typography,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper
 } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    height: 350,
    width: 270,
  },
  media: {
    maxHeight: 220
  },
  paper: {
    height: 320,
    width: 270,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  }
}));


export default function SubjectsView(props) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [subjects, setSubjects] = React.useState([]);
  const [activeLevels, setActiveLevels] = React.useState([]);

  const colorsAvailable = 
  ["#d0fffe",
  "#fffddb",
  "#e4ffde",
  "#ffd3fd",
  "#ffe7d3"];

  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;
 
  const classes = useStyles();
  
 

  React.useEffect(() => {

    isMounted.current = true;

    const refreshSubjects = () => {
    
        setLoading(true);
        axios.get(edusim.api_base_uri+"/api/instructors/managers/getMySubjects/",{
          headers: {
            'x-access-token': propsUser.token
          }
        }).then(res => {
          console.log(res);
          res.data.subjects.forEach((subject)=>{
            subject.bgcolor = colorsAvailable.length>=1?colorsAvailable.pop():"#ffffff"
          })
          setSubjects(res.data.subjects);
          setLoading(false);
        }).catch(e => {
          console.log(e);
          if(e.response){
              if(isMounted.current){
                  setLoading(false);
              }
              if(e.response.status === 403){
                  propsUpdateUser({});
              }
          }else{
              if(isMounted.current){
                  setLoading(false);
                  setError("Network connection might be lost, ")
              }                    
          }
        });
      
    }

    refreshSubjects();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [propsUpdateUser, propsUser]);

  React.useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) {
        if (error !== '') {
          setError('');
        }
      }
    }, 3000);
  }, [error]);

 
  const getLevelName = (_levelid) =>{
    let levelIndex = activeLevels.findIndex(e=>e._id===_levelid);
    return activeLevels[levelIndex]?activeLevels[levelIndex].name:'';
  }

  return (
    !props.user.active||!props.user.mobile?
      <Grid item xs={12}>
              <Alert style={{marginBottom: 10}} severity='error'>
                  Both your email and mobile must be verified to use this service
              </Alert>
      </Grid>
    :
    !propsUser._instructorid?
      <Grid item xs={12}>
              <Alert style={{marginBottom: 10}} severity='error'>
                  This service is only available to instructors. If you are an active instructor, contact your admin to be registered in the system.
              </Alert>
            </Grid>
    :

    <Container maxWidth="xl" className={classes.container}>
      {(error !== '') &&
        <Grid item xs={12}>
          <Alert style={{ marginBottom: 10 }} severity='error'>
            {error}
          </Alert>
        </Grid>
      }
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>
          <h2 style={{ padding: 0, margin: 0, flex: 1 }}>{props.scheduleView?"My Schedule":"My Subjects"}</h2>
        </Box>
      </Box>

      
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
            {(loading ? Array.from(new Array(3)) : subjects).map((obj, key) => (
              <Grid key={key} item>
                {obj ? (
                  <Link to={'/subjects/'+ obj._id} style={{ textDecoration: 'none' }}>
                    <Card className={classes.cardRoot}>
                      <CardHeader style={{backgroundColor: obj.bgcolor?obj.bgcolor:"#ffffff"}}
                        avatar={
                          <Avatar aria-label="Subject" className={classes.avatar}>
                            <ListAltSharpIcon />
                          </Avatar>
                        }
                        title={getLevelName(obj._levelid._id) + " " + obj.short_name}
                        subheader={obj.name}
                      />
                      <CardMedia
                        component="img"
                        className={classes.media}
                        src="https://nowheretobefound.net/e.jpg"
                        onError={(e) => { e.target.onerror = null; e.target.src = sectionAvatarPlaceholder }}
                        title={obj.name}
                      />
                      <CardContent>

                      {obj.translated_name}
                      </CardContent>
                    </Card>
                  </Link>
                ) :
                  <Skeleton variant="rect" width={180} height={220} />
                }
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

    
    </Container>

  );
}