import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import Alert from '@material-ui/lab/Alert';
import sectionAvatarInSchedulePlaceholder from '../imgs/sectionAvatarPlaceholder.png';

import { 
    Container,
    Grid,
    Box,
    Button,
    Typography,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    makeStyles,
    withStyles
} from '@material-ui/core';

import { Link } from "@reach/router";
import data from '../config/data';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    height: 350,
    width: 270,
  },
  media: {
    maxHeight: 220
  },
  paper: {
    height: 320,
    width: 270,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  }
}));


export default function SectionsView(props) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [sections, setSections] = React.useState([]);
  const [currentTermId, setCurrentTermId] = React.useState(3);
  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;
  const days = [0, 1, 2, 3, 4, 5];
  const periods = [0, 1, 2, 3, 4, 5, 6];

  React.useEffect(() => {

    isMounted.current = true;

    const refreshSections = () => {
      setLoading(true);
      const TermIndex = data.sample_sections.findIndex(e => e._termid === currentTermId);
      const SampleSections = TermIndex > -1 ? data.sample_sections[TermIndex].sections : [];
      setSections(SampleSections ? SampleSections : []);
      setLoading(false);
    }

    refreshSections();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [propsUpdateUser, propsUser, currentTermId]);

  React.useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) {
        if (error !== '') {
          setError('');
        }
      }
    }, 3000);
  }, [error]);

  const classes = useStyles();

  const TableHeaderCell = withStyles(theme => ({
    root: {
      fontWeight: 'bolder'
    }
  }))(TableCell);

  const nextTerm = () => {
    currentTermId + 1 < 5 ? setCurrentTermId(currentTermId + 1) : setCurrentTermId(currentTermId);
  }

  const prevTerm = () => {
    (currentTermId - 1 > 0) ? setCurrentTermId(currentTermId - 1) : setCurrentTermId(currentTermId);
  }

  const isInSectionSchedule = (section, day, period) => {
    let index = section.schedule.findIndex(o => o.day === day && o.period === period);
    return index >= 0 ? true : false;
  }
  
  return (

    <Container maxWidth="xl" className={classes.container}>
      {(error !== '') &&
        <Grid item xs={12}>
          <Alert style={{ marginBottom: 10 }} severity='error'>
            {error}
          </Alert>
        </Grid>
      }
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>
          <h2 style={{ padding: 0, margin: 0, flex: 1 }}>My Sections</h2>
        </Box>
      </Box>
      <Grid container 
        direction = "row"
        justify ='space-between'
        alignItems ="stretch">
                          
        <Grid item>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => prevTerm()}>
                Previous
          </Button>
        </Grid>

        <Grid item>
          <Typography component="h1" variant="h6" color ="primary">
            Term {currentTermId}
          </Typography>
        </Grid>

        <Grid item>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => nextTerm()}>
                Next
            </Button>
        </Grid>

      </Grid>

      <Table>
        <TableHead>
            <TableRow >
                <TableCell><b>Days/Periods</b></TableCell>
                {periods.map((period, key) =>
                <TableCell key={key}><b>P {period + 1}</b></TableCell>)}
            </TableRow>
        </TableHead>
        <TableBody>
            {days.map((day, key) =>
            <TableRow key={key}>
                <TableCell><b>Day {day + 1}</b></TableCell>
                {periods.map((period, key) =>(
                    <TableCell key={key}>
                    {(loading ? Array.from(new Array(3)) : sections).map((obj) => (
                        (obj && obj.schedule && isInSectionSchedule(obj, day, period) &&
                            <Link key={key} to={'/sections/' + currentTermId + "/" + obj._id} style={{ textDecoration: 'none' }}>
                                {obj.name}
                            </Link>)
                        
                    ))}
                    </TableCell>
                ))}
            </TableRow>
            )}  
        </TableBody>
    </Table>

    </Container>

  );
}