import React, { Fragment } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import {
    IconButton,
    Typography,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    useStyles,
    Grid,
    withStyles
} from '@material-ui/core';

import { 
    AddCircle as AddCircleIcon,     
} from '@material-ui/icons';

import PlanView from './PlanView';
import PlansView from './PlansView';
//import AttendanceView from './AttendanceView';
import FloatingActionButton from './FAB';

const PlanningView = (props) => {
    const [planVisible, setPlanVisible] = React.useState(false);
    const [editPlanVisible, setEditPlanVisible] = React.useState(false);
    const [selectedPlan, setSelectedPlan] = React.useState(null);
    const [plans, setPlans] = React.useState(props.section.plans);
    const section = props.section;

    const propsUser = props.propsUser;
    const propsUpdateUser = props.propsUpdateUser;

    const updatePlans = (plan) =>{
        let foundIndex = plans ? plans.findIndex(e => e._id === plan._id) : -1;
        if (foundIndex >= 0) {
            let tempArr = [...plans];
            tempArr[foundIndex] = plan;
            setPlans(tempArr);
        }
        props.updateSectionPlans(plan);
    }

    const addPlan = (plan) => {
        let tempArr = [...plans];
        tempArr.push(plan);
        setPlans(tempArr);
    }

    const handlePlanClick = (e)=> {    
        setPlanVisible(true);
    }
    
    /* const isInSchedule = (day, period) => {
        let index = section.schedule.findIndex(o => o.day === day && o.period === period);
        return index >= 0 ? true : false;
    } */

    const TableHeaderCell = withStyles(theme => ({
        root: {
          fontWeight: 'bolder'
        }
      }))(TableCell);

    return (
        <React.Fragment>
           
            {planVisible && 
            <PlanView 
            setPlanVisible={setPlanVisible}
            addPlan={addPlan} 
            section={section}
            plans={plans} 
            propsUser={propsUser}
            propsUpdateUser={propsUpdateUser} />}
            {!editPlanVisible && !planVisible &&
            <React.Fragment>
             <Grid 
             container 
             spacing={3}
             direction="row"
             justify="flex-start"
             alignItems="center">
                 <Grid item>
                     <Typography component="h2" variant="h6" color="primary" >Current Plans</Typography>
                 </Grid>
                 <Grid item>
                     <FloatingActionButton
                         variant="contained"
                         color="primary"
                         clickHandler={handlePlanClick} />
                 </Grid>
            </Grid>
            <PlansView 
            plans={plans}
            section={section} 
            setSelectedPlan={setSelectedPlan} 
            setEditPlanVisible={setEditPlanVisible}
            updatePlans={updatePlans}
            />
            </React.Fragment>}
            {editPlanVisible && 
            <PlanView 
            setEditPlanVisible={setEditPlanVisible} 
            editPlanVisible={editPlanVisible} 
            selectedPlan={selectedPlan}
            updatePlans={updatePlans} 
            section={section} 
            plans={plans} 
            propsUser={propsUser}
            propsUpdateUser={propsUpdateUser}
            />}
        </React.Fragment>
    )
}
export default PlanningView;