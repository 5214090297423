import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import Alert from '@material-ui/lab/Alert';

import { 
    Container,
    Grid,
    Box,
    Button,
    Typography,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    makeStyles,
    withStyles,
    FormControlLabel,
    FormGroup,
    Checkbox
} from '@material-ui/core';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment'
import { RRule, RRuleSet, rrulestr } from 'rrule'
import { StayCurrentLandscape } from '@material-ui/icons';

const localizer = momentLocalizer(moment)

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    height: 350,
    width: 270,
  },
  media: {
    maxHeight: 220
  },
  paper: {
    height: 320,
    width: 270,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  }
}));

export default function CalendarView(props) {
  const classes = useStyles();
  const [calEvents, setCalEvents] = React.useState([]);

  /* let rule = RRule.fromString("FREQ=WEEKLY;dtstart=20210905;WKST=SU;UNTIL=20211223T205959Z;BYDAY=MO,SU,TH,TU,WE");
  let datesArray = rule.all();
  let days = [0,1,2,3,4,5];
  let periodTimes = [{title:"Lineup", start:"07:00", end: "07:15"},
                    {title:"Period 1", start:"07:15", end: "08:00"},
                    {title:"Period 2", start:"08:00", end: "08:45"},
                    {title:"Period 3", start:"08:45", end: "09:30"},
                    {title:"Break 1", start:"09:30", end: "10:15"},
                    {title:"Period 4", start:"10:15", end: "11:00"},
                    {title:"Period 5", start:"11:00", end: "11:45"},
                    {title:"Break 2", start:"11:45", end: "12:30"},
                    {title:"Period 6", start:"12:30", end: "13:15"},
                    {title:"Period 7", start:"13:15", end: "14:00"},
                    ]; */

  const datesArray = props.calendarSettings.datesArray;
  const days = props.calendarSettings.days;
  const periodTimes = props.calendarSettings.periodTimes;
  
  function createEvents(){
    let eventsArray = [];
    let currDayIndex = 0;
    for(let i=0; i<datesArray.length; i++){
      if(currDayIndex===days.length)currDayIndex=0;
      eventsArray.push({
        title: "Day " + (days[currDayIndex] + 1),
        start: moment(datesArray[i].toString()).startOf("day").toDate(),
        end: moment(datesArray[i].toString()).startOf("day").toDate(),
      });
      currDayIndex++;
    }

    for(let i=0; i<datesArray.length; i++){
      for(let p=0; p<periodTimes.length; p++){
        let starting = moment(datesArray[i].toString()).startOf("day");
        starting.set({h: periodTimes[p].start.split(":")[0], m: periodTimes[p].start.split(":")[1]});
        let ending = moment(datesArray[i].toString()).startOf("day");
        ending.set({h: periodTimes[p].end.split(":")[0], m: periodTimes[p].end.split(":")[1]});
        eventsArray.push({
          title: periodTimes[p].title,
          start: starting.toDate(),
          end: ending.toDate(),
          bgColor: "#ffd3fd",
          textColor: "#000000"
        });

      }
      
    }

    return (eventsArray);
  }

  function eventStyleGetter(event, start, end, isSelected){
    if(event.bgColor&&event.textColor) return {style: {backgroundColor: event.bgColor, color: event.textColor}}
    else return {}
  }

  React.useEffect(()=>{
    let specialEvent = {
        title: "Arabic Week",
        start: moment("2021-10-10").startOf("day").toDate(),
        end: moment("2021-10-14").endOf("day").toDate(),
        bgColor: "#ffe7d3",
        textColor: "#000000"
    }
    let specialEvent2 = {
      title: "Math Week",
      start: moment("2021-10-24").startOf("day").toDate(),
      end: moment("2021-10-28").endOf("day").toDate(),
      bgColor: "#ffe7d3",
      textColor: "#000000"
    }
    let eventArr = createEvents();
    eventArr.push(specialEvent);
    eventArr.push(specialEvent2);
    setCalEvents(eventArr);
  },[]);

  return (
    
    <Container maxWidth="xl" className={classes.container}> 

      <Calendar
        localizer={localizer}
        events={calEvents}
        startAccessor="start"
        endAccessor="end"
        step={15}
        style={{ height: 1000 }}
        //onSelectSlot={(this.slotSelected)}
        //onSelectEvent={(this.eventSelected)}
        eventPropGetter={(eventStyleGetter)}
      />
      
    </Container>

  );
}