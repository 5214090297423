import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import {
    Typography,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Table,
    withStyles
} from '@material-ui/core'

import { 
    HowToReg as HowToRegIcon,
    Edit as EditIcon,     
} from '@material-ui/icons';

const TableHeaderCell = withStyles(theme => ({
    root: {
      fontWeight: 'bolder'
    }
  }))(TableCell);

const SessionsView = (props) => {
    const sessions = props.sessions;
    const setSelectedSession = props.setSelectedSession;
    const setEditSessionVisible = props.setEditSessionVisible;
    const setAttendanceVisible = props.setAttendanceVisible;

    const handleOpenAttendance = (e, session) => {
        setSelectedSession(session);
        setAttendanceVisible(true);
    }
    
    const handleEditSession = (e, session) => {
        setEditSessionVisible(true);
        setSelectedSession(session);
    }

    return (
        <React.Fragment>            
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>
                            Day/Period
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Title
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Tags
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Calendar Day
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Actions
                        </TableHeaderCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {sessions && sessions.map((obj, key) => (
                    <TableRow key={key}>
                        <TableCell>
                            Day {obj.day+1} / Period {obj.period+1}
                        </TableCell>
                        <TableCell>
                                {obj.title}
                        </TableCell>
                        <TableCell>
                            {obj.tags?.map((tag,key)=>(
                                key===0?tag:(", "+tag)
                            ))}
                        </TableCell>
                        <TableCell>
                            {obj.actualSessionDateTime}
                        </TableCell>
                        <TableCell>
                            <Tooltip title="Edit Session" aria-label="edit">
                                <IconButton  variant="contained" color="primary" onClick={(e) => handleEditSession (e, obj)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit Attendance" aria-label="Add">
                                <IconButton  variant="contained" color="primary" onClick={(e) => handleOpenAttendance(e, obj)}>
                                    <HowToRegIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    )
}
export default SessionsView;