import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';

import Box from '@material-ui/core/Box';

import data from '../config/data';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  studentAvatar:{
    backgroundColor: 'transparent'
  },
  wrapper: {
    position: 'relative',
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
  uploadButton: {
    width:'100%'
  },
  dropZoneClass:{
    maxHeight: '200px'
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
    /* flexBasis: '33.33%',
    flexShrink: 0, */

  },
  infoHeadingBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 0,
  },
  infoBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
  },
  infoSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  enrollmentsListRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  accordianHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function SectionView(props) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [sectionId, setSectionId] = React.useState(parseInt(props._sectionid));
  const propsUser = props.user;
  const [section, setSection] = React.useState({});

  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;

  const propsTermId = parseInt(props._termid); 
 
  const classes = useStyles();
   
  React.useEffect(()=>{
      setTimeout(()=>{
        if(error !== ''){
          setError('');
        }
      }, 3000);
  },[error]);

  React.useEffect(()=>{

    isMounted.current = true;
    
    const loadSection = () => {
      setLoading(true);
      const TermIndex = data.sample_sections.findIndex(e=>e._termid===propsTermId);
      const TermSections = data.sample_sections[TermIndex].sections;
      const Section = TermSections[TermSections.findIndex(e=>e._id===sectionId)];
      console.log(Section);
      setSection(Section);
      setLoading(false);
    };

    //load existing section
    if(sectionId)
      loadSection();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [propsTermId, sectionId, propsUpdateUser, propsUser]);

  return (
    
    <Container /* maxWidth="lg" */ className={classes.container}>
    <Box>
    {(error !== '') &&
        <Alert style={{marginBottom: 10}} severity='error'>
            {error}
        </Alert>
      }
    </Box>
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
      {sectionId &&
      <h2 style={{padding:0, margin:0}}> {section.name?section.name : <Skeleton animation="wave" />}</h2>
      }
      </Box>
    </Box>

    <Paper className={classes.paper}>

    
    </Paper>
    
    </Container>
  );
}