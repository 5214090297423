import React, { Fragment } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import {
    IconButton,
    Typography,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    useStyles,
    Grid,
    withStyles
} from '@material-ui/core';

import { 
    AddCircle as AddCircleIcon,     
} from '@material-ui/icons';

import SessionView from './SessionView';
import SessionsView from './SessionsView';
import AttendanceView from './AttendanceView';
import FloatingActionButton from './FAB';

const ScheduleView = (props) => {
    const [sessionVisible, setSessionVisible] = React.useState(false);
    const [editSessionVisible, setEditSessionVisible] = React.useState(false);
    const [attendanceVisible, setAttendanceVisible] = React.useState(false);
    const [selectedDay, setSelectedDay]= React.useState(null);
    const [selectedPeriod,setSelectedPeriod] = React.useState(null);
    const [selectedSession, setSelectedSession] = React.useState(null);
    const [sessions, setSessions] = React.useState(props.section.sessions);
    const section = props.section;
    const days = [0, 1, 2, 3, 4, 5];
    const periods = [0, 1, 2, 3, 4, 5, 6];

    const propsUser = props.propsUser;
    const propsUpdateUser = props.propsUpdateUser;

    const updateSessions = (session) =>{
        let foundIndex = sessions ? sessions.findIndex(e => e._id === session._id) : -1;
        if (foundIndex >= 0) {
            let tempArr = [...sessions];
            tempArr[foundIndex] = session;
            setSessions(tempArr);
        }
        props.updateSectionSessions(session);
    }

    const addSession = (session) => {
        let tempArr = [...sessions];
        tempArr.push(session);
        setSessions(tempArr);
        //props.addSectionAssignment(assignment);
    }

    const handleSessionClick = (e, day,period)=> {    
        setSessionVisible(true);
        setSelectedDay(day);
        setSelectedPeriod (period);
    }
    
    const isInSchedule = (day, period) => {
        let index = section.schedule.findIndex(o => o.day === day && o.period === period);
        return index >= 0 ? true : false;
    }

    const TableHeaderCell = withStyles(theme => ({
        root: {
          fontWeight: 'bolder'
        }
      }))(TableCell);
    
    const SessionInScheduleView = () => {
        const [addSessionVisible, setAddSessionVisible] = React.useState(false);
        const toggleAddSession = () => {
            setAddSessionVisible(!addSessionVisible);
        }
        return (
            <React.Fragment>
                <Grid 
                container 
                spacing={3}
                direction="row"
                justify="flex-start"
                alignItems="center">
                    <Grid item>
                        <Typography component="h2" variant="h6" color="primary" >Current Sessions</Typography>
                    </Grid>
                    <Grid item>
                        <FloatingActionButton
                            variant="contained"
                            color="primary"
                            clickHandler={toggleAddSession} />
                    </Grid>
                </Grid>
               
                {addSessionVisible&&
                <Fragment>

                <Typography component="h3" variant="h6" color="primary" gutterBottom> 
                    {section.schedule.length} periods scheduled during the cycle.
                </Typography>
                <Table>
                    <TableHead>
                    <TableRow >
                    <TableHeaderCell>Days/Periods</TableHeaderCell>
                        {periods.map((period, key) =>
                            <TableHeaderCell key={key}>P {period + 1}</TableHeaderCell>)}
                </TableRow>
                    </TableHead>
                    <TableBody>
                        {days.map((day, key) =>
                            <TableRow key={key}>
                                <TableHeaderCell>Day {day + 1}</TableHeaderCell>
                                {periods.map((period, key) =>
                                    <TableCell key={key}>
                                        {section && section.schedule && isInSchedule(day, period) &&
                                            <Tooltip title="Add session" aria-label="add">
                                                <IconButton variant="contained" color="primary"  onClick={(e) => 
                                                        handleSessionClick(e, day,period)} >
                                                    <AddCircleIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </TableCell>
                                )}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                </Fragment>
            }
            </React.Fragment>
        )
    }

    

    return (
        <React.Fragment>
            {sessionVisible && 
            <SessionView 
            setSessionVisible={setSessionVisible} 
            calendarSettings={props.calendarSettings}
            selectedDay={selectedDay}
            selectedPeriod={selectedPeriod} 
            addSession={addSession} 
            section={section}
            sessions={sessions} 
            propsUser={propsUser}
            propsUpdateUser={propsUpdateUser} />}
            {!sessionVisible && !editSessionVisible && !attendanceVisible && 
            <SessionInScheduleView />}
            {!editSessionVisible && !sessionVisible && !attendanceVisible && 
            <SessionsView 
            sessions={sessions} 
            setSelectedSession={setSelectedSession} 
            setEditSessionVisible={setEditSessionVisible}
            setAttendanceVisible={setAttendanceVisible} 
            updateSessions={updateSessions}
            />}
            {editSessionVisible && 
            <SessionView 
            setEditSessionVisible={setEditSessionVisible} 
            calendarSettings={props.calendarSettings}
            editSessionVisible={editSessionVisible} 
            selectedSession={selectedSession}
            updateSessions={updateSessions} 
            section={section} 
            sessions={sessions} 
            propsUser={propsUser}
            propsUpdateUser={propsUpdateUser}
            />}
            {attendanceVisible && 
            <AttendanceView 
            setAttendanceVisible={setAttendanceVisible} 
            section={section} 
            selectedSession={selectedSession} 
            updateSessions={updateSessions} 
            propsUser={propsUser}
            propsUpdateUser={propsUpdateUser}
            />}
        </React.Fragment>
    )
}
export default ScheduleView;