import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import {
    Typography,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Table,
    withStyles
} from '@material-ui/core'

import { 
    HowToReg as HowToRegIcon,
    Edit as EditIcon,     
} from '@material-ui/icons';

const TableHeaderCell = withStyles(theme => ({
    root: {
      fontWeight: 'bolder'
    }
  }))(TableCell);



const PlansView = (props) => {
    const plans = props.plans;
    const setSelectedPlan = props.setSelectedPlan;
    const setEditPlanVisible = props.setEditPlanVisible;

    const section = props.section;
    
    const handleEditPlan = (e, plan) => {
        setEditPlanVisible(true);
        setSelectedPlan(plan);
    }
    function getSessionSelectTitle(sessionid){
        let foundIndex = section.sessions.findIndex(s=>s._id===sessionid);
        if(foundIndex>-1)
            return section.sessions[foundIndex].title + ' - ' + (section.sessions[foundIndex].actualSessionDateTime?section.sessions[foundIndex].actualSessionDateTime:'No Calendar Day')
        else
            return 'Not Found'
    }

    return (
        <React.Fragment>            
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>
                            Title/Topic
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Session(s)
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Attachments
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Actions
                        </TableHeaderCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {plans && plans.map((obj, key) => (
                    <TableRow key={key}>
                        <TableCell>
                            {obj.title}
                        </TableCell>
                        <TableCell>
                            {obj.sessions?.map((sessionid)=>(
                                <p>{getSessionSelectTitle(sessionid)}</p>
                            ))}
                        </TableCell>
                        <TableCell>
                            {obj.attachments?.length || 0} Attachments
                        </TableCell>
                        <TableCell>
                            <Tooltip title="Edit Plan" aria-label="edit">
                                <IconButton  variant="contained" color="primary" onClick={(e) => handleEditPlan (e, obj)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    )
}
export default PlansView;