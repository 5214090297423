import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from "@reach/router";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import Alert from '@material-ui/lab/Alert';

import Box from '@material-ui/core/Box';

import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';

import sectionAvatarPlaceholder from '../imgs/sectionAvatarPlaceholder.png';
import Typography from '@material-ui/core/Typography';


import data from '../config/data';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    height: 350,
    width: 270,
  },
  media: {
    maxHeight: 220
  },
  paper: {
    height: 320,
    width: 270,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  }
}));


export default function SectionsView(props) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [sections, setSections] = React.useState([]);
  const [currentTermId, setCurrentTermId] = React.useState(3);
  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;

  React.useEffect(() => {

    isMounted.current = true;

    const refreshSections = () => {
      console.log(currentTermId);
      const TermIndex = data.sample_sections.findIndex(e => e._termid === currentTermId);

      const SampleSections = TermIndex > -1 ? data.sample_sections[TermIndex].sections : [];
      setSections(SampleSections ? SampleSections : []);
    }

    refreshSections();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [propsUpdateUser, propsUser, currentTermId]);

  React.useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) {
        if (error !== '') {
          setError('');
        }
      }
    }, 3000);
  }, [error]);

  const classes = useStyles();

  const nextTerm = () => {
    currentTermId + 1 < 5 ? setCurrentTermId(currentTermId + 1) : setCurrentTermId(currentTermId);
  }

  const prevTerm = () => {
    (currentTermId - 1 > 0) ? setCurrentTermId(currentTermId - 1) : setCurrentTermId(currentTermId);
  }
  return (

    <Container /* maxWidth="lg" */ className={classes.container}>
      {(error !== '') &&
        <Grid item xs={12}>
          <Alert style={{ marginBottom: 10 }} severity='error'>
            {error}
          </Alert>
        </Grid>
      }
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>
          <h2 style={{ padding: 0, margin: 0, flex: 1 }}>Manage Sections</h2>
        </Box>
      </Box>

      <Grid container
        direction="row"
        justify ="space-between"
        alignItems ="stretch">
        
        <Grid item>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => prevTerm()}
          >
            Previous
          </Button>
        </Grid>

        <Grid item>
          <Typography component="h1" variant="h6" color ="primary">
            Term {currentTermId}
          </Typography>
        </Grid>

        <Grid item>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => nextTerm()}
          >
            Next
          </Button>
        </Grid>
      </Grid>

      <Grid container
        spacing={2}
        style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <Grid container
            spacing={4}>
            {(loading ? Array.from(new Array(3)) : sections).map((obj, key) => (
              <Grid key={key} item>
                {obj ? (
                  <Link to={'/sections/' + currentTermId + "/" + obj._id} style={{ textDecoration: 'none' }}>
                    <Card className={classes.cardRoot}>
                      <CardHeader
                        avatar={
                          <Avatar aria-label="Section" className={classes.avatar}>
                            <ListAltSharpIcon />
                          </Avatar>
                        }
                        title={obj.name}
                        subheader={obj.name}
                      />
                      <CardMedia
                        component="img"
                        className={classes.media}
                        src="https://nowhetetobefound.ne/te.jpg"
                        onError={(e) => { e.target.onerror = null; e.target.src = sectionAvatarPlaceholder }}
                        title={obj.name}
                      />
                      <CardContent>


                      </CardContent>
                    </Card>
                  </Link>
                ) :
                  <Skeleton variant="rect" width={180} height={220} />
                }
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>

  );
}