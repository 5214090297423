export default {
    sample_user: {
        _id: "5e85be2bd62a2c2b5fc14ea6",
        active: true,
        instructor: {
            _id: "5ea0b9bbd567923a17e1943e",
            _userid: "5e85be2bd62a2c2b5fc14ea6",
            dateofbirth: "1982-11-18T00:00:00.000Z",
            email: "mina@cloudibs.com",
            mobile: "+97339290099",
            gender: "male",
            name: "Mina Banoub Farag",
            nickname: "minab",
            nationalid: "7911331233",
            nationality: { Name: "Egypt", Value: "Egypt", Code: "EG", Id: 63 },
            passportid: "12312312",
            office: "3rd Building, Office 11",
            fp: "f",
            active: true
        },
        message: "Enjoy your token!",
        mobile: "+97339290099",
        realname: "Mina Banoub Farag",
        success: true,
        token: "eyJ0eXAiOiJKV1QiLCJhbGcyOiJIUzI1NiJ9.eyJpZCI6ImV6ei5oYW50YXNoQGdtYWlsLmNvbSIsIl9pZCI6IjVlODViZTJiZDYyYTJjMmI1ZmMxNGVhNiIsImFjdGl2ZSI6dHJ1ZSw",
        username: "mina@cloudibs.com"
    },
    sample_sections: [
        {
            _termid: 1,
            sections: []
        },
        {
            _termid: 2,
            sections: []
        },
        {
            _termid: 3,
            sections: [
                { _id: 1, name: "Section 1" },
                {
                    _id: 2, name: "Section 2", students: [{ _id: 123, name: "Ezz" },{ _id: 345, name: "Mina" },{ _id: 456, name: "Jo" }],
                    assignments: [
                        { 
                            _id: 1,
                            _categoryid: 1,
                            _intervalid: 2,
                            interval: "Quarter 1",
                            name: "Assignment 1", 
                            shortName: "A1",
                            points: 10, 
                            bonusPoints: 2, 
                            dueDate: "", 
                            dueTime: "",
                            assessment: false, 
                            inClass: true, 
                            published: true, 
                            instructions: "students must write an essay",
                            earned_points: {123:10, 345:9}
                        },
                        {
                            _id: 2,
                            _categoryid: 2,
                            _intervalid: 1,
                            name: "Assignment 2",
                            shortName: "A2",
                            points: 10,
                            bonusPoints: 2,
                            interval: "Quarter 1",
                            dueDate: "",
                            dueTime: "",
                            assessment: true,
                            inClass: false,
                            published: true,
                            instructions: "students must carry out an internet research",
                            earned_points: {123:9,345:8,456:10}
                        },
                        {
                            _id: 3,
                            _categoryid: 3,
                            _intervalid: 2,
                            name: "Assignment 3",
                            shortName: "A3",
                            points: 20,
                            bonusPoints: 2,
                            interval: "Quarter 1",
                            dueDate: "",
                            dueTime: "",
                            assessment: true,
                            inClass: false,
                            published: true,
                            instructions: "students must carry out an internet research",
                            earned_points: {}
                        }],
                    schedule: [
                        {day:0, period:0},
                        {day:3, period:1},
                        {day:4, period:5}
                    ],
                    sessions: [
                        {   _id:1,
                            title:"Session 1",
                            tags: ["first session","Revision", "Unit 1"],
                            duration: 40,
                            schedule_day: 0,
                            schedule_period: 0,
                            started: "2021-03-11T13:00",
                            ended:"2021-03-11T13:40",
                            remote_students_link: "www.google.com",
                            remote_session_recording_link:"www.microsoft.com",
                            absences: [123],
                            tardies: {123:10}
                        },
                        {
                            _id:2,
                            title:"Session 2",
                            tags: ["BODMAS Rule","Math", "Unit 1"],
                            duration: 40,
                            schedule_day: 3,
                            schedule_period: 1,
                            started: "2021-03-13T13:00",
                            ended:"2021-03-13T13:40",
                            remote_students_link: "www.theverge.com",
                            remote_session_recording_link:"www.theforage.com"
                        }
                    ],
                    categoryTemplate: {
                        categories:[
                            {_id: 1, 
                            name: "Numbers",
                            weightRatio: 0.25},
                            {_id: 2, 
                            name: "Letters",
                            weightRatio: 0.25},
                            {_id: 3, 
                            name: "Shapes",
                            weightRatio: 0.5},
                        ]
                    },
                    intervals: [
                        {_id:1,name:"Quarter 1", shortName: "Q1"},
                        {_id:2,name:"Quarter 2", shortName: "Q2"}
                    ]
                }
            ]
        },
        {
            _termid: 4,
            sections: []
        }
    ],
    countries: [
        {
            "Name": "Åland Islands",
            "Value": "Åland Islands",
            "Code": "AX",
            "Id": 1
        },
        {
            "Name": "Albania",
            "Value": "Albania",
            "Code": "AL",
            "Id": 2
        },
        {
            "Name": "Algeria",
            "Value": "Algeria",
            "Code": "DZ",
            "Id": 3
        },
        {
            "Name": "American Samoa",
            "Value": "American Samoa",
            "Code": "AS",
            "Id": 4
        },
        {
            "Name": "AndorrA",
            "Value": "AndorrA",
            "Code": "AD",
            "Id": 5
        },
        {
            "Name": "Angola",
            "Value": "Angola",
            "Code": "AO",
            "Id": 6
        },
        {
            "Name": "Anguilla",
            "Value": "Anguilla",
            "Code": "AI",
            "Id": 7
        },
        {
            "Name": "Antarctica",
            "Value": "Antarctica",
            "Code": "AQ",
            "Id": 8
        },
        {
            "Name": "Antigua and Barbuda",
            "Value": "Antigua and Barbuda",
            "Code": "AG",
            "Id": 9
        },
        {
            "Name": "Argentina",
            "Value": "Argentina",
            "Code": "AR",
            "Id": 10
        },
        {
            "Name": "Armenia",
            "Value": "Armenia",
            "Code": "AM",
            "Id": 11
        },
        {
            "Name": "Aruba",
            "Value": "Aruba",
            "Code": "AW",
            "Id": 12
        },
        {
            "Name": "Australia",
            "Value": "Australia",
            "Code": "AU",
            "Id": 13
        },
        {
            "Name": "Austria",
            "Value": "Austria",
            "Code": "AT",
            "Id": 14
        },
        {
            "Name": "Azerbaijan",
            "Value": "Azerbaijan",
            "Code": "AZ",
            "Id": 15
        },
        {
            "Name": "Bahamas",
            "Value": "Bahamas",
            "Code": "BS",
            "Id": 16
        },
        {
            "Name": "Bahrain",
            "Value": "Bahrain",
            "Code": "BH",
            "Id": 17
        },
        {
            "Name": "Bangladesh",
            "Value": "Bangladesh",
            "Code": "BD",
            "Id": 18
        },
        {
            "Name": "Barbados",
            "Value": "Barbados",
            "Code": "BB",
            "Id": 19
        },
        {
            "Name": "Belarus",
            "Value": "Belarus",
            "Code": "BY",
            "Id": 20
        },
        {
            "Name": "Belgium",
            "Value": "Belgium",
            "Code": "BE",
            "Id": 21
        },
        {
            "Name": "Belize",
            "Value": "Belize",
            "Code": "BZ",
            "Id": 22
        },
        {
            "Name": "Benin",
            "Value": "Benin",
            "Code": "BJ",
            "Id": 23
        },
        {
            "Name": "Bermuda",
            "Value": "Bermuda",
            "Code": "BM",
            "Id": 24
        },
        {
            "Name": "Bhutan",
            "Value": "Bhutan",
            "Code": "BT",
            "Id": 25
        },
        {
            "Name": "Bolivia",
            "Value": "Bolivia",
            "Code": "BO",
            "Id": 26
        },
        {
            "Name": "Bosnia and Herzegovina",
            "Value": "Bosnia and Herzegovina",
            "Code": "BA",
            "Id": 27
        },
        {
            "Name": "Botswana",
            "Value": "Botswana",
            "Code": "BW",
            "Id": 28
        },
        {
            "Name": "Bouvet Island",
            "Value": "Bouvet Island",
            "Code": "BV",
            "Id": 29
        },
        {
            "Name": "Brazil",
            "Value": "Brazil",
            "Code": "BR",
            "Id": 30
        },
        {
            "Name": "British Indian Ocean Territory",
            "Value": "British Indian Ocean Territory",
            "Code": "IO",
            "Id": 31
        },
        {
            "Name": "Brunei Darussalam",
            "Value": "Brunei Darussalam",
            "Code": "BN",
            "Id": 32
        },
        {
            "Name": "Bulgaria",
            "Value": "Bulgaria",
            "Code": "BG",
            "Id": 33
        },
        {
            "Name": "Burkina Faso",
            "Value": "Burkina Faso",
            "Code": "BF",
            "Id": 34
        },
        {
            "Name": "Burundi",
            "Value": "Burundi",
            "Code": "BI",
            "Id": 35
        },
        {
            "Name": "Cambodia",
            "Value": "Cambodia",
            "Code": "KH",
            "Id": 36
        },
        {
            "Name": "Cameroon",
            "Value": "Cameroon",
            "Code": "CM",
            "Id": 37
        },
        {
            "Name": "Canada",
            "Value": "Canada",
            "Code": "CA",
            "Id": 38
        },
        {
            "Name": "Cape Verde",
            "Value": "Cape Verde",
            "Code": "CV",
            "Id": 39
        },
        {
            "Name": "Cayman Islands",
            "Value": "Cayman Islands",
            "Code": "KY",
            "Id": 40
        },
        {
            "Name": "Central African Republic",
            "Value": "Central African Republic",
            "Code": "CF",
            "Id": 41
        },
        {
            "Name": "Chad",
            "Value": "Chad",
            "Code": "TD",
            "Id": 42
        },
        {
            "Name": "Chile",
            "Value": "Chile",
            "Code": "CL",
            "Id": 43
        },
        {
            "Name": "China",
            "Value": "China",
            "Code": "CN",
            "Id": 44
        },
        {
            "Name": "Christmas Island",
            "Value": "Christmas Island",
            "Code": "CX",
            "Id": 45
        },
        {
            "Name": "Cocos (Keeling) Islands",
            "Value": "Cocos (Keeling) Islands",
            "Code": "CC",
            "Id": 46
        },
        {
            "Name": "Colombia",
            "Value": "Colombia",
            "Code": "CO",
            "Id": 47
        },
        {
            "Name": "Comoros",
            "Value": "Comoros",
            "Code": "KM",
            "Id": 48
        },
        {
            "Name": "Congo",
            "Value": "Congo",
            "Code": "CG",
            "Id": 49
        },
        {
            "Name": "Congo, The Democratic Republic of the",
            "Value": "Congo, The Democratic Republic of the",
            "Code": "CD",
            "Id": 50
        },
        {
            "Name": "Cook Islands",
            "Value": "Cook Islands",
            "Code": "CK",
            "Id": 51
        },
        {
            "Name": "Costa Rica",
            "Value": "Costa Rica",
            "Code": "CR",
            "Id": 52
        },
        {
            "Name": "Cote D\"Ivoire",
            "Value": "Cote D\"Ivoire",
            "Code": "CI",
            "Id": 53
        },
        {
            "Name": "Croatia",
            "Value": "Croatia",
            "Code": "HR",
            "Id": 54
        },
        {
            "Name": "Cuba",
            "Value": "Cuba",
            "Code": "CU",
            "Id": 55
        },
        {
            "Name": "Cyprus",
            "Value": "Cyprus",
            "Code": "CY",
            "Id": 56
        },
        {
            "Name": "Czech Republic",
            "Value": "Czech Republic",
            "Code": "CZ",
            "Id": 57
        },
        {
            "Name": "Denmark",
            "Value": "Denmark",
            "Code": "DK",
            "Id": 58
        },
        {
            "Name": "Djibouti",
            "Value": "Djibouti",
            "Code": "DJ",
            "Id": 59
        },
        {
            "Name": "Dominica",
            "Value": "Dominica",
            "Code": "DM",
            "Id": 60
        },
        {
            "Name": "Dominican Republic",
            "Value": "Dominican Republic",
            "Code": "DO",
            "Id": 61
        },
        {
            "Name": "Ecuador",
            "Value": "Ecuador",
            "Code": "EC",
            "Id": 62
        },
        {
            "Name": "Egypt",
            "Value": "Egypt",
            "Code": "EG",
            "Id": 63
        },
        {
            "Name": "El Salvador",
            "Value": "El Salvador",
            "Code": "SV",
            "Id": 64
        },
        {
            "Name": "Equatorial Guinea",
            "Value": "Equatorial Guinea",
            "Code": "GQ",
            "Id": 65
        },
        {
            "Name": "Eritrea",
            "Value": "Eritrea",
            "Code": "ER",
            "Id": 66
        },
        {
            "Name": "Estonia",
            "Value": "Estonia",
            "Code": "EE",
            "Id": 67
        },
        {
            "Name": "Ethiopia",
            "Value": "Ethiopia",
            "Code": "ET",
            "Id": 68
        },
        {
            "Name": "Falkland Islands (Malvinas)",
            "Value": "Falkland Islands (Malvinas)",
            "Code": "FK",
            "Id": 69
        },
        {
            "Name": "Faroe Islands",
            "Value": "Faroe Islands",
            "Code": "FO",
            "Id": 70
        },
        {
            "Name": "Fiji",
            "Value": "Fiji",
            "Code": "FJ",
            "Id": 71
        },
        {
            "Name": "Finland",
            "Value": "Finland",
            "Code": "FI",
            "Id": 72
        },
        {
            "Name": "France",
            "Value": "France",
            "Code": "FR",
            "Id": 73
        },
        {
            "Name": "French Guiana",
            "Value": "French Guiana",
            "Code": "GF",
            "Id": 74
        },
        {
            "Name": "French Polynesia",
            "Value": "French Polynesia",
            "Code": "PF",
            "Id": 75
        },
        {
            "Name": "French Southern Territories",
            "Value": "French Southern Territories",
            "Code": "TF",
            "Id": 76
        },
        {
            "Name": "Gabon",
            "Value": "Gabon",
            "Code": "GA",
            "Id": 77
        },
        {
            "Name": "Gambia",
            "Value": "Gambia",
            "Code": "GM",
            "Id": 78
        },
        {
            "Name": "Georgia",
            "Value": "Georgia",
            "Code": "GE",
            "Id": 79
        },
        {
            "Name": "Germany",
            "Value": "Germany",
            "Code": "DE",
            "Id": 80
        },
        {
            "Name": "Ghana",
            "Value": "Ghana",
            "Code": "GH",
            "Id": 81
        },
        {
            "Name": "Gibraltar",
            "Value": "Gibraltar",
            "Code": "GI",
            "Id": 82
        },
        {
            "Name": "Greece",
            "Value": "Greece",
            "Code": "GR",
            "Id": 83
        },
        {
            "Name": "Greenland",
            "Value": "Greenland",
            "Code": "GL",
            "Id": 84
        },
        {
            "Name": "Grenada",
            "Value": "Grenada",
            "Code": "GD",
            "Id": 85
        },
        {
            "Name": "Guadeloupe",
            "Value": "Guadeloupe",
            "Code": "GP",
            "Id": 86
        },
        {
            "Name": "Guam",
            "Value": "Guam",
            "Code": "GU",
            "Id": 87
        },
        {
            "Name": "Guatemala",
            "Value": "Guatemala",
            "Code": "GT",
            "Id": 88
        },
        {
            "Name": "Guernsey",
            "Value": "Guernsey",
            "Code": "GG",
            "Id": 89
        },
        {
            "Name": "Guinea",
            "Value": "Guinea",
            "Code": "GN",
            "Id": 90
        },
        {
            "Name": "Guinea-Bissau",
            "Value": "Guinea-Bissau",
            "Code": "GW",
            "Id": 91
        },
        {
            "Name": "Guyana",
            "Value": "Guyana",
            "Code": "GY",
            "Id": 92
        },
        {
            "Name": "Haiti",
            "Value": "Haiti",
            "Code": "HT",
            "Id": 93
        },
        {
            "Name": "Heard Island and Mcdonald Islands",
            "Value": "Heard Island and Mcdonald Islands",
            "Code": "HM",
            "Id": 94
        },
        {
            "Name": "Holy See (Vatican City State)",
            "Value": "Holy See (Vatican City State)",
            "Code": "VA",
            "Id": 95
        },
        {
            "Name": "Honduras",
            "Value": "Honduras",
            "Code": "HN",
            "Id": 96
        },
        {
            "Name": "Hong Kong",
            "Value": "Hong Kong",
            "Code": "HK",
            "Id": 97
        },
        {
            "Name": "Hungary",
            "Value": "Hungary",
            "Code": "HU",
            "Id": 98
        },
        {
            "Name": "Iceland",
            "Value": "Iceland",
            "Code": "IS",
            "Id": 99
        },
        {
            "Name": "India",
            "Value": "India",
            "Code": "IN",
            "Id": 100
        },
        {
            "Name": "Indonesia",
            "Value": "Indonesia",
            "Code": "ID",
            "Id": 101
        },
        {
            "Name": "Iran, Islamic Republic Of",
            "Value": "Iran, Islamic Republic Of",
            "Code": "IR",
            "Id": 102
        },
        {
            "Name": "Iraq",
            "Value": "Iraq",
            "Code": "IQ",
            "Id": 103
        },
        {
            "Name": "Ireland",
            "Value": "Ireland",
            "Code": "IE",
            "Id": 104
        },
        {
            "Name": "Isle of Man",
            "Value": "Isle of Man",
            "Code": "IM",
            "Id": 105
        },
        {
            "Name": "Israel",
            "Value": "Israel",
            "Code": "IL",
            "Id": 106
        },
        {
            "Name": "Italy",
            "Value": "Italy",
            "Code": "IT",
            "Id": 107
        },
        {
            "Name": "Jamaica",
            "Value": "Jamaica",
            "Code": "JM",
            "Id": 108
        },
        {
            "Name": "Japan",
            "Value": "Japan",
            "Code": "JP",
            "Id": 109
        },
        {
            "Name": "Jersey",
            "Value": "Jersey",
            "Code": "JE",
            "Id": 110
        },
        {
            "Name": "Jordan",
            "Value": "Jordan",
            "Code": "JO",
            "Id": 111
        },
        {
            "Name": "Kazakhstan",
            "Value": "Kazakhstan",
            "Code": "KZ",
            "Id": 112
        },
        {
            "Name": "Kenya",
            "Value": "Kenya",
            "Code": "KE",
            "Id": 113
        },
        {
            "Name": "Kiribati",
            "Value": "Kiribati",
            "Code": "KI",
            "Id": 114
        },
        {
            "Name": "Korea, Democratic People\"S Republic of",
            "Value": "Korea, Democratic People\"S Republic of",
            "Code": "KP",
            "Id": 115
        },
        {
            "Name": "Korea, Republic of",
            "Value": "Korea, Republic of",
            "Code": "KR",
            "Id": 116
        },
        {
            "Name": "Kuwait",
            "Value": "Kuwait",
            "Code": "KW",
            "Id": 117
        },
        {
            "Name": "Kyrgyzstan",
            "Value": "Kyrgyzstan",
            "Code": "KG",
            "Id": 118
        },
        {
            "Name": "Lao People\"S Democratic Republic",
            "Value": "Lao People\"S Democratic Republic",
            "Code": "LA",
            "Id": 119
        },
        {
            "Name": "Latvia",
            "Value": "Latvia",
            "Code": "LV",
            "Id": 120
        },
        {
            "Name": "Lebanon",
            "Value": "Lebanon",
            "Code": "LB",
            "Id": 121
        },
        {
            "Name": "Lesotho",
            "Value": "Lesotho",
            "Code": "LS",
            "Id": 122
        },
        {
            "Name": "Liberia",
            "Value": "Liberia",
            "Code": "LR",
            "Id": 123
        },
        {
            "Name": "Libyan Arab Jamahiriya",
            "Value": "Libyan Arab Jamahiriya",
            "Code": "LY",
            "Id": 124
        },
        {
            "Name": "Liechtenstein",
            "Value": "Liechtenstein",
            "Code": "LI",
            "Id": 125
        },
        {
            "Name": "Lithuania",
            "Value": "Lithuania",
            "Code": "LT",
            "Id": 126
        },
        {
            "Name": "Luxembourg",
            "Value": "Luxembourg",
            "Code": "LU",
            "Id": 127
        },
        {
            "Name": "Macao",
            "Value": "Macao",
            "Code": "MO",
            "Id": 128
        },
        {
            "Name": "Macedonia, The Former Yugoslav Republic of",
            "Value": "Macedonia, The Former Yugoslav Republic of",
            "Code": "MK",
            "Id": 129
        },
        {
            "Name": "Madagascar",
            "Value": "Madagascar",
            "Code": "MG",
            "Id": 130
        },
        {
            "Name": "Malawi",
            "Value": "Malawi",
            "Code": "MW",
            "Id": 131
        },
        {
            "Name": "Malaysia",
            "Value": "Malaysia",
            "Code": "MY",
            "Id": 132
        },
        {
            "Name": "Maldives",
            "Value": "Maldives",
            "Code": "MV",
            "Id": 133
        },
        {
            "Name": "Mali",
            "Value": "Mali",
            "Code": "ML",
            "Id": 134
        },
        {
            "Name": "Malta",
            "Value": "Malta",
            "Code": "MT",
            "Id": 135
        },
        {
            "Name": "Marshall Islands",
            "Value": "Marshall Islands",
            "Code": "MH",
            "Id": 136
        },
        {
            "Name": "Martinique",
            "Value": "Martinique",
            "Code": "MQ",
            "Id": 137
        },
        {
            "Name": "Mauritania",
            "Value": "Mauritania",
            "Code": "MR",
            "Id": 138
        },
        {
            "Name": "Mauritius",
            "Value": "Mauritius",
            "Code": "MU",
            "Id": 139
        },
        {
            "Name": "Mayotte",
            "Value": "Mayotte",
            "Code": "YT",
            "Id": 140
        },
        {
            "Name": "Mexico",
            "Value": "Mexico",
            "Code": "MX",
            "Id": 141
        },
        {
            "Name": "Micronesia, Federated States of",
            "Value": "Micronesia, Federated States of",
            "Code": "FM",
            "Id": 142
        },
        {
            "Name": "Moldova, Republic of",
            "Value": "Moldova, Republic of",
            "Code": "MD",
            "Id": 143
        },
        {
            "Name": "Monaco",
            "Value": "Monaco",
            "Code": "MC",
            "Id": 144
        },
        {
            "Name": "Mongolia",
            "Value": "Mongolia",
            "Code": "MN",
            "Id": 145
        },
        {
            "Name": "Montserrat",
            "Value": "Montserrat",
            "Code": "MS",
            "Id": 146
        },
        {
            "Name": "Morocco",
            "Value": "Morocco",
            "Code": "MA",
            "Id": 147
        },
        {
            "Name": "Mozambique",
            "Value": "Mozambique",
            "Code": "MZ",
            "Id": 148
        },
        {
            "Name": "Myanmar",
            "Value": "Myanmar",
            "Code": "MM",
            "Id": 149
        },
        {
            "Name": "Namibia",
            "Value": "Namibia",
            "Code": "NA",
            "Id": 150
        },
        {
            "Name": "Nauru",
            "Value": "Nauru",
            "Code": "NR",
            "Id": 151
        },
        {
            "Name": "Nepal",
            "Value": "Nepal",
            "Code": "NP",
            "Id": 152
        },
        {
            "Name": "Netherlands",
            "Value": "Netherlands",
            "Code": "NL",
            "Id": 153
        },
        {
            "Name": "Netherlands Antilles",
            "Value": "Netherlands Antilles",
            "Code": "AN",
            "Id": 154
        },
        {
            "Name": "New Caledonia",
            "Value": "New Caledonia",
            "Code": "NC",
            "Id": 155
        },
        {
            "Name": "New Zealand",
            "Value": "New Zealand",
            "Code": "NZ",
            "Id": 156
        },
        {
            "Name": "Nicaragua",
            "Value": "Nicaragua",
            "Code": "NI",
            "Id": 157
        },
        {
            "Name": "Niger",
            "Value": "Niger",
            "Code": "NE",
            "Id": 158
        },
        {
            "Name": "Nigeria",
            "Value": "Nigeria",
            "Code": "NG",
            "Id": 159
        },
        {
            "Name": "Niue",
            "Value": "Niue",
            "Code": "NU",
            "Id": 160
        },
        {
            "Name": "Norfolk Island",
            "Value": "Norfolk Island",
            "Code": "NF",
            "Id": 161
        },
        {
            "Name": "Northern Mariana Islands",
            "Value": "Northern Mariana Islands",
            "Code": "MP",
            "Id": 162
        },
        {
            "Name": "Norway",
            "Value": "Norway",
            "Code": "NO",
            "Id": 163
        },
        {
            "Name": "Oman",
            "Value": "Oman",
            "Code": "OM",
            "Id": 164
        },
        {
            "Name": "Pakistan",
            "Value": "Pakistan",
            "Code": "PK",
            "Id": 165
        },
        {
            "Name": "Palau",
            "Value": "Palau",
            "Code": "PW",
            "Id": 166
        },
        {
            "Name": "Palestinian Territory, Occupied",
            "Value": "Palestinian Territory, Occupied",
            "Code": "PS",
            "Id": 167
        },
        {
            "Name": "Panama",
            "Value": "Panama",
            "Code": "PA",
            "Id": 168
        },
        {
            "Name": "Papua New Guinea",
            "Value": "Papua New Guinea",
            "Code": "PG",
            "Id": 169
        },
        {
            "Name": "Paraguay",
            "Value": "Paraguay",
            "Code": "PY",
            "Id": 170
        },
        {
            "Name": "Peru",
            "Value": "Peru",
            "Code": "PE",
            "Id": 171
        },
        {
            "Name": "Philippines",
            "Value": "Philippines",
            "Code": "PH",
            "Id": 172
        },
        {
            "Name": "Pitcairn",
            "Value": "Pitcairn",
            "Code": "PN",
            "Id": 173
        },
        {
            "Name": "Poland",
            "Value": "Poland",
            "Code": "PL",
            "Id": 174
        },
        {
            "Name": "Portugal",
            "Value": "Portugal",
            "Code": "PT",
            "Id": 175
        },
        {
            "Name": "Puerto Rico",
            "Value": "Puerto Rico",
            "Code": "PR",
            "Id": 176
        },
        {
            "Name": "Qatar",
            "Value": "Qatar",
            "Code": "QA",
            "Id": 177
        },
        {
            "Name": "Reunion",
            "Value": "Reunion",
            "Code": "RE",
            "Id": 178
        },
        {
            "Name": "Romania",
            "Value": "Romania",
            "Code": "RO",
            "Id": 179
        },
        {
            "Name": "Russian Federation",
            "Value": "Russian Federation",
            "Code": "RU",
            "Id": 180
        },
        {
            "Name": "RWANDA",
            "Value": "RWANDA",
            "Code": "RW",
            "Id": 181
        },
        {
            "Name": "Saint Helena",
            "Value": "Saint Helena",
            "Code": "SH",
            "Id": 182
        },
        {
            "Name": "Saint Kitts and Nevis",
            "Value": "Saint Kitts and Nevis",
            "Code": "KN",
            "Id": 183
        },
        {
            "Name": "Saint Lucia",
            "Value": "Saint Lucia",
            "Code": "LC",
            "Id": 184
        },
        {
            "Name": "Saint Pierre and Miquelon",
            "Value": "Saint Pierre and Miquelon",
            "Code": "PM",
            "Id": 185
        },
        {
            "Name": "Saint Vincent and the Grenadines",
            "Value": "Saint Vincent and the Grenadines",
            "Code": "VC",
            "Id": 186
        },
        {
            "Name": "Samoa",
            "Value": "Samoa",
            "Code": "WS",
            "Id": 187
        },
        {
            "Name": "San Marino",
            "Value": "San Marino",
            "Code": "SM",
            "Id": 188
        },
        {
            "Name": "Sao Tome and Principe",
            "Value": "Sao Tome and Principe",
            "Code": "ST",
            "Id": 189
        },
        {
            "Name": "Saudi Arabia",
            "Value": "Saudi Arabia",
            "Code": "SA",
            "Id": 190
        },
        {
            "Name": "Senegal",
            "Value": "Senegal",
            "Code": "SN",
            "Id": 191
        },
        {
            "Name": "Serbia and Montenegro",
            "Value": "Serbia and Montenegro",
            "Code": "CS",
            "Id": 192
        },
        {
            "Name": "Seychelles",
            "Value": "Seychelles",
            "Code": "SC",
            "Id": 193
        },
        {
            "Name": "Sierra Leone",
            "Value": "Sierra Leone",
            "Code": "SL",
            "Id": 194
        },
        {
            "Name": "Singapore",
            "Value": "Singapore",
            "Code": "SG",
            "Id": 195
        },
        {
            "Name": "Slovakia",
            "Value": "Slovakia",
            "Code": "SK",
            "Id": 196
        },
        {
            "Name": "Slovenia",
            "Value": "Slovenia",
            "Code": "SI",
            "Id": 197
        },
        {
            "Name": "Solomon Islands",
            "Value": "Solomon Islands",
            "Code": "SB",
            "Id": 198
        },
        {
            "Name": "Somalia",
            "Value": "Somalia",
            "Code": "SO",
            "Id": 199
        },
        {
            "Name": "South Africa",
            "Value": "South Africa",
            "Code": "ZA",
            "Id": 200
        },
        {
            "Name": "South Georgia and the South Sandwich Islands",
            "Value": "South Georgia and the South Sandwich Islands",
            "Code": "GS",
            "Id": 201
        },
        {
            "Name": "Spain",
            "Value": "Spain",
            "Code": "ES",
            "Id": 202
        },
        {
            "Name": "Sri Lanka",
            "Value": "Sri Lanka",
            "Code": "LK",
            "Id": 203
        },
        {
            "Name": "Sudan",
            "Value": "Sudan",
            "Code": "SD",
            "Id": 204
        },
        {
            "Name": "Suriname",
            "Value": "Suriname",
            "Code": "SR",
            "Id": 205
        },
        {
            "Name": "Svalbard and Jan Mayen",
            "Value": "Svalbard and Jan Mayen",
            "Code": "SJ",
            "Id": 206
        },
        {
            "Name": "Swaziland",
            "Value": "Swaziland",
            "Code": "SZ",
            "Id": 207
        },
        {
            "Name": "Sweden",
            "Value": "Sweden",
            "Code": "SE",
            "Id": 208
        },
        {
            "Name": "Switzerland",
            "Value": "Switzerland",
            "Code": "CH",
            "Id": 209
        },
        {
            "Name": "Syrian Arab Republic",
            "Value": "Syrian Arab Republic",
            "Code": "SY",
            "Id": 210
        },
        {
            "Name": "Taiwan, Province of China",
            "Value": "Taiwan, Province of China",
            "Code": "TW",
            "Id": 211
        },
        {
            "Name": "Tajikistan",
            "Value": "Tajikistan",
            "Code": "TJ",
            "Id": 212
        },
        {
            "Name": "Tanzania, United Republic of",
            "Value": "Tanzania, United Republic of",
            "Code": "TZ",
            "Id": 213
        },
        {
            "Name": "Thailand",
            "Value": "Thailand",
            "Code": "TH",
            "Id": 214
        },
        {
            "Name": "Timor-Leste",
            "Value": "Timor-Leste",
            "Code": "TL",
            "Id": 215
        },
        {
            "Name": "Togo",
            "Value": "Togo",
            "Code": "TG",
            "Id": 216
        },
        {
            "Name": "Tokelau",
            "Value": "Tokelau",
            "Code": "TK",
            "Id": 217
        },
        {
            "Name": "Tonga",
            "Value": "Tonga",
            "Code": "TO",
            "Id": 218
        },
        {
            "Name": "Trinidad and Tobago",
            "Value": "Trinidad and Tobago",
            "Code": "TT",
            "Id": 219
        },
        {
            "Name": "Tunisia",
            "Value": "Tunisia",
            "Code": "TN",
            "Id": 220
        },
        {
            "Name": "Turkey",
            "Value": "Turkey",
            "Code": "TR",
            "Id": 221
        },
        {
            "Name": "Turkmenistan",
            "Value": "Turkmenistan",
            "Code": "TM",
            "Id": 222
        },
        {
            "Name": "Turks and Caicos Islands",
            "Value": "Turks and Caicos Islands",
            "Code": "TC",
            "Id": 223
        },
        {
            "Name": "Tuvalu",
            "Value": "Tuvalu",
            "Code": "TV",
            "Id": 224
        },
        {
            "Name": "Uganda",
            "Value": "Uganda",
            "Code": "UG",
            "Id": 225
        },
        {
            "Name": "Ukraine",
            "Value": "Ukraine",
            "Code": "UA",
            "Id": 226
        },
        {
            "Name": "United Arab Emirates",
            "Value": "United Arab Emirates",
            "Code": "AE",
            "Id": 227
        },
        {
            "Name": "United Kingdom",
            "Value": "United Kingdom",
            "Code": "GB",
            "Id": 228
        },
        {
            "Name": "United States",
            "Value": "United States",
            "Code": "US",
            "Id": 229
        },
        {
            "Name": "United States Minor Outlying Islands",
            "Value": "United States Minor Outlying Islands",
            "Code": "UM",
            "Id": 230
        },
        {
            "Name": "Uruguay",
            "Value": "Uruguay",
            "Code": "UY",
            "Id": 231
        },
        {
            "Name": "Uzbekistan",
            "Value": "Uzbekistan",
            "Code": "UZ",
            "Id": 232
        },
        {
            "Name": "Vanuatu",
            "Value": "Vanuatu",
            "Code": "VU",
            "Id": 233
        },
        {
            "Name": "Venezuela",
            "Value": "Venezuela",
            "Code": "VE",
            "Id": 234
        },
        {
            "Name": "Viet Nam",
            "Value": "Viet Nam",
            "Code": "VN",
            "Id": 235
        },
        {
            "Name": "Virgin Islands, British",
            "Value": "Virgin Islands, British",
            "Code": "VG",
            "Id": 236
        },
        {
            "Name": "Virgin Islands, U.S.",
            "Value": "Virgin Islands, U.S.",
            "Code": "VI",
            "Id": 237
        },
        {
            "Name": "Wallis and Futuna",
            "Value": "Wallis and Futuna",
            "Code": "WF",
            "Id": 238
        },
        {
            "Name": "Western Sahara",
            "Value": "Western Sahara",
            "Code": "EH",
            "Id": 239
        },
        {
            "Name": "Yemen",
            "Value": "Yemen",
            "Code": "YE",
            "Id": 240
        },
        {
            "Name": "Zambia",
            "Value": "Zambia",
            "Code": "ZM",
            "Id": 241
        },
        {
            "Name": "Zimbabwe",
            "Value": "Zimbabwe",
            "Code": "ZW",
            "Id": 242
        }
    ]
};