import React from 'react';

import {
    Typography,
    TextField,
    Grid,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from '@material-ui/core';

import ChipInput from 'material-ui-chip-input';

import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import edusim from '../config/edusim';
import moment from 'moment';

const SessionView = (props) => {
    
    const selectedDay = props.selectedDay;
    const selectedPeriod = props.selectedPeriod;
    const defaultSession = props.selectedSession? {
        ...props.selectedSession
    }:{
        typical_duration: 40, day:selectedDay, period: selectedPeriod, tags:[]
    }
    const [session, setSession] = React.useState(defaultSession);
    const [sessionDatesTimes, setSessionDatesTimes] = React.useState([]);
    const setSessionVisible = props.setSessionVisible;
    const setEditSessionVisible = props.setEditSessionVisible;
    const editSessionVisible = props.editSessionVisible;
    const selectedSession = props.selectedSession;
    const updateSessions = props.updateSessions;
    const addSession = props.addSession;
    const section = props.section;
    const sessions = props.sessions;

    const propsUser = props.propsUser;
    const propsUpdateUser = props.propsUpdateUser;

    const isMounted = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    //error handling states
    const [error, setError] = React.useState('');
    const [errors, setErrors] = React.useState({});
    const [errorMessages, setErrorMessages] = React.useState({});

    const datesArray = props.calendarSettings.datesArray;
    const days = props.calendarSettings.days;
    const periodTimes = props.calendarSettings.periodTimes;

    const handleSaveSession = (e) =>{
        setSession(session=>({...session, validate:true, validateCallBack: (validSession)=>{
            if(!validSession){
              setError('Please fix the required information before proceeding.');
              setSession(session=>({...session, validateCallBack: null}));
            }else{
              _saveSession();
            }
        }}));
    }

    const _saveSession = () =>{
        console.log(session);
        setLoading(true);
        axios.post(edusim.api_base_uri+"/api/instructors/sections/"+section._id+"/sessions/"+session._id, 
        {session: {...session}}, {
            headers: {
            'x-access-token': propsUser.token
            }
        }).then(res => {
            console.log(res);
            updateSessions(session)
            setEditSessionVisible(false);
        }).catch(e => {
            if(e.response){
                if(isMounted.current){
                    setLoading(false);
                    setEditSessionVisible(false);
                }
                if(e.response.status === 403){
                    propsUpdateUser({});
                }
            }else{
                if(isMounted.current){
                    setLoading(false);
                    setError("Network connection might be lost");
                }                    
            }
        });
    }
    
    const handleAddSession = (e) =>{
        setSession(session=>({...session, validate:true, validateCallBack: (validSession)=>{
            if(!validSession){
              setError('Please fix the required information before proceeding.');
              setSession(session=>({...session, validateCallBack: null}));
            }else{
              _addSession();
            }
        }}));
    }

    const _addSession = () => {
        setLoading(true);
      axios.post(edusim.api_base_uri+"/api/instructors/sections/"+section._id+"/sessions", 
      {session: {_sectionid: section._id, _subjectid: section._subjectid, ...session}}, {
        headers: {
          'x-access-token': propsUser.token
        }
      }).then(res => {
        console.log(res);
        let tempSession = {...session};
        tempSession._id = res.data;
        addSession(tempSession);
        setLoading(false);
        setSessionVisible(false);
      }).catch(e => {
        if(e.response){
            if(isMounted.current){
                setLoading(false);
                //setNewAssignmentVisible(false);
            }
            if(e.response.status === 403){
                propsUpdateUser({});
            }
        }else{
            if(isMounted.current){
                setLoading(false);
                setError("Network connection might be lost, ")
            }                    
        }
      });
        /* addSession(session);
        setSessionVisible(false); */
    }

    const handleTextFieldChange = (e, fieldName) => {
        switch (fieldName) {
            case 'sessionTitle':
                setSession({ ...session, title: e.target.value.trim() });
                break;
            case 'sessionTags':
                setSession({ ...session, tags: e.target.value.trim().split(',') });
                break;
            case 'sessionRemoteLink':
                setSession({ ...session, remote_students_link: e.target.value.trim() });
                break;
            case 'sessionRemoteRecordingLink':
                setSession({ ...session, remote_session_recording_link: e.target.value.trim() });
                break;
            default:
                console.log("default case");
        }
    }

    const handleCloseClick = (e) => {
        editSessionVisible ? setEditSessionVisible(false) : setSessionVisible(false);
    }

    const handleSelectActualDay = (e) => {
        setSession({ ...session, actualSessionDateTime: e.target.value!=="Unassigned"?e.target.value:null });
    }

    const handleTagsChange = (chips) => {
        setSession({ ...session, tags: chips });
    }

    const possibleNotInSessions = (possible) =>{
        let findVal = possible.dayName + " " + isoStringDate(possible.start) + " (" + isoStringTime(possible.start) + " - " + isoStringTime(possible.end) + ")";
        let foundIndex = sessions.findIndex((s)=>s.actualSessionDateTime===findVal);
        return foundIndex===-1;
    }

    React.useEffect(()=>{
        let validatedSession = session;
        
        const validateFields = () => {
          let sessionValid = true;
          
          if(!validatedSession.title){
            sessionValid = false;
            setErrors(errors=> ({...errors, 'title': true}));
            setErrorMessages(errorMessages => ({...errorMessages, 'title': 'Required!'}));
          }else if(validatedSession.title.length < 5){
            sessionValid = false;
            setErrors(errors=> ({...errors, 'title': true}));
            setErrorMessages(errorMessages=> ({...errorMessages, 'title': 'Minimum of 5 characters is required'}));
          }else{
            cancelErrors('title');
          }
    
          if(validatedSession.remote_students_link&&!validLink(validatedSession.remote_students_link, false)){
            sessionValid = false;
            setErrors(errors=>({...errors, 'remote_students_link': true}));
            setErrorMessages(errorMessages=>({...errorMessages, 'remote_students_link': 'Valid url like https://zoom.us'}));
          }else{
            cancelErrors('remote_students_link');
          }

          if(validatedSession.remote_session_recording_link&&!validLink(validatedSession.remote_session_recording_link, false)){
            sessionValid = false;
            setErrors(errors=>({...errors, 'remote_session_recording_link': true}));
            setErrorMessages(errorMessages=>({...errorMessages, 'remote_session_recording_link': 'Valid url like https://zoom.us'}));
          }else{
            cancelErrors('remote_session_recording_link');
          }

         if(validatedSession.validateCallBack)
            validatedSession.validateCallBack(sessionValid);
        
        }
    
        const cancelErrors = (fieldName) => {
          setErrors(errors=>({...errors, [fieldName]: false}));
          setErrorMessages(errorMessages=>({...errorMessages, [fieldName]: null}));
        }
    
        /* const validDate = (date, required) => {
          //we are only interested in the first 10 charachters of this string
          if(!required && date.length===0) return true;
          let checkedDate = date.split("T")[0];
          if (moment(checkedDate?checkedDate:date, "YYYY-MM-DD", true).isValid()) {
            return true;
          } else {
            return false;
          }
        }
        
        const validTime = (time, required) =>{
          if(!required && time.length===0) return true;
          return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
        }
 */
        const validLink = (link, required) =>{
            if(!required && link.length===0) return true;
            
            try {
                new URL(link);
              } catch (e) {
                return false;
              }
              return true;
        }
    
        if(validatedSession.validate){
          validateFields();
        }

        const getPossibleDatesTimes = ()=>{
            let datesTimes = [];
            let currDayIndex = 0;
            for(let i=0; i<datesArray.length; i++){
                if(currDayIndex===days.length)currDayIndex=0;
                for(let p=0; p<periodTimes.length; p++){
                    //console.log("curr day", currDayIndex, selectedDay, periodTimes[p].title, "Period "+(selectedPeriod+1));
                    if(currDayIndex===session.day && (periodTimes[p].title=== "Period " + (session.period+1))){
                        let starting = moment(datesArray[i].toString()).startOf("day");
                        starting.set({h: periodTimes[p].start.split(":")[0], m: periodTimes[p].start.split(":")[1]});
                        let ending = moment(datesArray[i].toString()).startOf("day");
                        ending.set({h: periodTimes[p].end.split(":")[0], m: periodTimes[p].end.split(":")[1]});
                        datesTimes.push({
                            title: periodTimes[p].title,
                            dayName: starting.toDate().toString().split(" ")[0],
                            start: starting.toISOString(true),
                            end: ending.toISOString(true)
                        });
                    }
                }
                currDayIndex++;
            }
            return datesTimes;
        }

        setSessionDatesTimes(getPossibleDatesTimes());

      },[session]);
    
    React.useEffect(()=>{
        setTimeout(()=>{
            if(error !== ''){
            setError('');
            }
        }, 3000);
    },[error]);

    function isoStringDate(dateString){
        return dateString.split("T")[0];
    }

    function isoStringTime(dateString){
        return dateString.split("T")[1].split(":")[0] + ":" + dateString.split("T")[1].split(":")[1];
    }

    return (
        <React.Fragment>
            {(error !== '') &&
            <Alert style={{marginBottom: 10}} severity='error'>
                {error}
            </Alert>
            }
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                {editSessionVisible ? 'Edit' : 'Add'} Session
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <TextField
                        defaultValue={editSessionVisible ? selectedSession.day+1 : selectedDay+1}
                        label="Schedule Day"
                        type="number"
                        disabled={true}
                        variant="outlined" 
                        fullWidth>
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        defaultValue={editSessionVisible ? selectedSession.period+1 : selectedPeriod+1}
                        label="Schedule Period"
                        type="number"
                        disabled={true}
                        variant="outlined" 
                        fullWidth>
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        defaultValue={editSessionVisible ? selectedSession.typical_duration : session.typical_duration}
                        label="Typical Duration (Minutes)"
                        type="number"
                        disabled={true}
                        variant="outlined"
                        fullWidth>
                    </TextField>
                </Grid>
                
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        required
                        error={errors['title']? true: false}
                        helperText={errorMessages['title']? errorMessages['title']: null}
                        onChange={(e) => handleTextFieldChange(e, 'sessionTitle')}
                        defaultValue={selectedSession ? selectedSession.title : ''}
                        label="Session Title (required)"
                        variant="outlined" 
                        fullWidth
                        autoFocus>
                    </TextField>
                </Grid>
                

                
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                    <InputLabel id="calendar-day-label">Calendar Day</InputLabel>
                    <Select
                    id="calendar-day"
                    labelId="calendar-day-label"
                    label="Calendar Day"
                    defaultValue={editSessionVisible&&session&&session.actualSessionDateTime ? session.actualSessionDateTime : 'Unassigned'} 
                    onChange = {(e)=>handleSelectActualDay(e)}
                    >
                        <MenuItem value={'Unassigned'}>Unassigned</MenuItem>
                        {sessionDatesTimes.map((possible, key)=>(
                            (possibleNotInSessions(possible)||(session&&session.actualSessionDateTime&& session.actualSessionDateTime ===possible.dayName + " " + isoStringDate(possible.start) + " (" + isoStringTime(possible.start) + " - " + isoStringTime(possible.end) + ")")) &&
                            <MenuItem key={key} value={possible.dayName + " " + isoStringDate(possible.start) + " (" + isoStringTime(possible.start) + " - " + isoStringTime(possible.end) + ")"}>
                                {possible.dayName + " " + isoStringDate(possible.start) + " (" + isoStringTime(possible.start) + " - " + isoStringTime(possible.end) + ")"}
                            </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Grid>
              

                
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                    <ChipInput
                        onChange={(chips) => handleTagsChange(chips)}
                        defaultValue={editSessionVisible ? selectedSession.tags : []}
                        label="Tags"
                        variant="outlined" 
                        fullWidth>
                    </ChipInput>
                    </FormControl>
                    
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        onChange={(e) => handleTextFieldChange(e, 'sessionRemoteLink')}
                        error={errors['remote_students_link']? true: false}
                        helperText={errorMessages['remote_students_link']? errorMessages['remote_students_link']: null}
                        defaultValue={editSessionVisible ? selectedSession.remote_students_link : ''}
                        label="Remote Session Link"
                        variant="outlined" 
                        fullWidth>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        onChange={(e) => handleTextFieldChange(e, 'sessionRemoteRecordingLink')}
                        error={errors['remote_session_recording_link']? true: false}
                        helperText={errorMessages['remote_session_recording_link']? errorMessages['remote_session_recording_link']: null}
                        defaultValue={editSessionVisible ? selectedSession.remote_session_recording_link : ''}
                        label="Session Recording Link"
                        variant="outlined" 
                        fullWidth>
                    </TextField>
                </Grid>
            </Grid>

            <Grid container 
                direction="row"
                justify="flex-end"
                alignItems="flex-end" spacing={3}>
                <Grid item>
                    <Button
                        onClick={(e) => handleCloseClick(e)}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => editSessionVisible ? handleSaveSession(e):handleAddSession(e)}>
                        {editSessionVisible ? "Save" : "Add"} Session
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
export default SessionView;