import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import {
    Typography,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Table,
    withStyles
} from '@material-ui/core'


import Box from '@material-ui/core/Box';

import data from '../config/data';

import AssignmentsView from './AssignmentsView';
import ScheduleView from './ScheduleView';
import GradeBookView from './GradeBookView';
import GradeSummaryView from './GradeSummaryView';
import PlanningView from './PlanningView';

import axios from 'axios';
import edusim from '../config/edusim';

const TableHeaderCell = withStyles(theme => ({
    root: {
      fontWeight: 'bolder'
    }
  }))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  studentAvatar:{
    backgroundColor: 'transparent'
  },
  wrapper: {
    position: 'relative',
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
  uploadButton: {
    width:'100%'
  },
  dropZoneClass:{
    maxHeight: '200px'
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
    /* flexBasis: '33.33%',
    flexShrink: 0, */

  },
  infoHeadingBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 0,
  },
  infoBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
  },
  infoSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  enrollmentsListRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  accordianHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const SubjectInstructorsView = (props) => {
    const subject = props.subject;
    /* const setSelectedSession = props.setSelectedSession;
    const setEditSessionVisible = props.setEditSessionVisible;
    const setAttendanceVisible = props.setAttendanceVisible; */

    /* const handleOpenAttendance = (e, session) => {
        setSelectedSession(session);
        setAttendanceVisible(true);
    }
    
    const handleEditSession = (e, session) => {
        setEditSessionVisible(true);
        setSelectedSession(session);
    } */

    return (
        <React.Fragment> 
            <Typography component="h2" variant="h6" color="primary" >Possible Instructors</Typography>          
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>
                            Instructor
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Nickname
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Sections
                        </TableHeaderCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {subject.instructors && subject.instructors.map((obj, key) => (
                    <TableRow key={key}>
                        <TableCell>
                            {obj._instructorid.name}
                        </TableCell>
                        <TableCell>
                            {obj._instructorid.nickname}
                        </TableCell>
                        <TableCell>
                            
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Typography style={{marginTop: 20}} component="h2" variant="h6" color="primary" >Subject Manager(s)</Typography>          
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>
                            Manager
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Nickname
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Sections
                        </TableHeaderCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {subject.managers && subject.managers.map((obj, key) => (
                    <TableRow key={key}>
                        <TableCell>
                            {obj._instructorid.name}
                        </TableCell>
                        <TableCell>
                            {obj._instructorid.nickname}
                        </TableCell>
                        <TableCell>
                            
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    )
}
export default SubjectInstructorsView;