import React from 'react';

import {
  Box,
  Typography,
  Grid,
  Button,
  InputBase,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  makeStyles
} from '@material-ui/core';
import axios from 'axios';
import edusim from '../config/edusim';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import maleAvatarPlaceholder from '../imgs/maleAvatarPlaceholder.png';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(0),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(0),
  },
}));

const StudentBadge = (props) =>{
  const student = props.student;
  return (
          <ListItem>
          <ListItemAvatar>
            <Avatar
              alt={student.name}
              src={maleAvatarPlaceholder}
            />
          </ListItemAvatar>
          <ListItemText primary={student.name} />
          
        </ListItem>
  )
}

const AssignmentGradeEntry = (props) => {
  //const [assignment, setAssignment] = React.useState(props.selectedAssignment);
  const setAssignmentGradesVisible = props.setAssignmentGradesVisible;
  const selectedAssignment = props.selectedAssignment;
  const section = props.section;
  const points = selectedAssignment.points;
  const bonus = selectedAssignment.bonus_points;
  const [assignmentGrades, setAssignmentGrades] = React.useState(props.selectedAssignment.earned_points ? props.selectedAssignment.earned_points : {});
  const updateAssignments = props.updateAssignments;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const propsUser = props.propsUser;
  const isMounted = React.useRef(null);
  const propsUpdateUser = props.propsUpdateUser;
  
  const handleAssignmentGradeClose = (e) => {
    setAssignmentGradesVisible(false);
  }

  const handleAssignmentGradeSave = () => {
   /*  let tempObj = selectedAssignment;
    tempObj.earned_points = assignmentGrades; */

    /* updateAssignments(tempObj);
    setAssignmentGradesVisible(false); */
    setLoading(true);
      axios.post(edusim.api_base_uri+"/api/instructors/sections/"+section._id+"/assignments/points/"+selectedAssignment._id, 
      {earned_points: {...assignmentGrades}}, {
        headers: {
          'x-access-token': propsUser.token
        }
      }).then(res => {
        console.log(res);
        let tempAssignment = {...selectedAssignment};
        tempAssignment.earned_points = {...assignmentGrades};
        updateAssignments(tempAssignment);
        setLoading(false);
        setAssignmentGradesVisible(false)
      }).catch(e => {
        if(e.response){
            if(isMounted.current){
                setLoading(false);
                setAssignmentGradesVisible(false);
            }
            if(e.response.status === 403){
                propsUpdateUser({});
            }
        }else{
            if(isMounted.current){
                setLoading(false);
                setError("Network connection might be lost, ")
            }                    
        }
      });
    
  }

  const handleGradeEntryKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      console.log(e.target.value);
      e.target.blur();
    }
  }

  const handleGradeEntryFocus = (e) => {
    e.target.setAttribute("data-old-value", e.target.value);
  }

  const handleGradeEntryBlur = (e, studentid) => {
    let assignmentGradesCopy = {...assignmentGrades};
    if (isNaN(e.target.value)
      || parseFloat(e.target.value) < 0
      || parseFloat(e.target.value) > (points + bonus)) {
      e.target.value = e.target.getAttribute("data-old-value");
    }
    else {
      let newValue = parseFloat(e.target.value);
      if(isNaN(newValue)){
        if(assignmentGradesCopy&&assignmentGradesCopy[studentid]){
          delete assignmentGradesCopy[studentid];
          setAssignmentGrades(assignmentGradesCopy);
        }
      }else{
        assignmentGradesCopy[studentid] = newValue;
        setAssignmentGrades(assignmentGradesCopy);
      }
      
    }

  }

  return (
    <React.Fragment>
      <Typography component = "h2" variant = "h6" color ="primary">{selectedAssignment && selectedAssignment.name} out of {selectedAssignment.points} Points, 
      ({selectedAssignment.bonus_points} Bonus)
      </Typography>
    <Box m={1}>

    
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Student Name
            </TableCell>
            <TableCell>
              Points Earned
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {section.students && section.students.map((obj, key) => (
            <TableRow key={key}>
              <TableCell>
                <StudentBadge student={obj} />
              </TableCell>
              <TableCell>
                <InputBase placeholder="Earned Points"
                  defaultValue={selectedAssignment.earned_points?selectedAssignment.earned_points[obj._id]:''}
                  inputProps={{
                    readOnly: false,
                    onFocus: (e) => handleGradeEntryFocus(e),
                    onKeyDown: (e) => handleGradeEntryKeyPress(e),
                    onBlur: (e) => handleGradeEntryBlur(e, obj._id)
                  }}>
                </InputBase>
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
      </Box>
      <Grid>

      </Grid>
      <Grid
      container spacing={6}                     
      direction="row"
      justify="flex-end"
      alignItems="center">
     
        <Grid item>
        <Button onClick={(e) => handleAssignmentGradeClose(e)}>
          Cancel
        </Button>
        </Grid>
        <Grid item> 
          <Button 
            variant="contained" 
            color="primary" 
            onClick={(e) => handleAssignmentGradeSave(e)}>
              Save Grades
          </Button>
        </Grid>
        </Grid>
    </React.Fragment>
  );
}
export default AssignmentGradeEntry;