import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from "@reach/router";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import edusim from '../config/edusim';

import Alert from '@material-ui/lab/Alert';

import Box from '@material-ui/core/Box';

import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';

import sectionAvatarPlaceholder from '../imgs/sectionAvatarPlaceholder.png';

import data from '../config/data';
import { Typography,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper
 } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    height: 350,
    width: 270,
  },
  media: {
    maxHeight: 220
  },
  paper: {
    height: 320,
    width: 270,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  }
}));


export default function SectionsView(props) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [sections, setSections] = React.useState([]);
  const [activeTerms, setActiveTerms] = React.useState([]);
  const [currentTerm, setCurrentTerm] = React.useState(null/* "6035fbd81d9d2c00062cfb66" */);
  const [activeLevels, setActiveLevels] = React.useState([]);
  const [termPeriods, setTermPeriods] = React.useState([]);

  const colorsAvailable = 
  ["#d0fffe",
  "#fffddb",
  "#e4ffde",
  "#ffd3fd",
  "#ffe7d3"];

  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;

  const days = [0, 1, 2, 3, 4, 5];
  const periods = [0, 1, 2, 3, 4, 5, 6];
 
  const classes = useStyles();
  
  React.useEffect(() => {
    isMounted.current = true;
    const refreshTermsLevels = () => {
      axios.get(edusim.api_base_uri+"/api/instructors/termslevels",{
        headers: {
          'x-access-token': propsUser.token
        }
        }).then(res => {
          console.log("terms levels res", res);
          setLoading(false);
          setActiveTerms(res.data.terms);
          setActiveLevels(res.data.levels);
          setCurrentTerm(res.data.terms?res.data.terms.length>0?res.data.terms[0]:null:null);
        }).catch(e => {
          if(e.response){
              if(isMounted.current){
                  setLoading(false);
              }
              if(e.response.status === 403){
                  propsUpdateUser({});
              }
          }else{
              if(isMounted.current){
                  setLoading(false);
                  setError("Network connection might be lost, ")
              }                    
          }
        });
    }

    refreshTermsLevels();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [propsUpdateUser, propsUser]);


  React.useEffect(() => {

    isMounted.current = true;

    const refreshSections = () => {
      //console.log.apply(propsUser);
      if(currentTerm){
        setLoading(true);
        axios.get(edusim.api_base_uri+"/api/instructors/getMySections/"+currentTerm._id,{
          headers: {
            'x-access-token': propsUser.token
          }
        }).then(res => {
          console.log(res);
          res.data.forEach((section)=>{
            section.bgcolor = colorsAvailable.length>=1?colorsAvailable.pop():"#ffffff"
          })
          setSections(res.data);
          setLoading(false);
        }).catch(e => {
          if(e.response){
              if(isMounted.current){
                  setLoading(false);
              }
              if(e.response.status === 403){
                  propsUpdateUser({});
              }
          }else{
              if(isMounted.current){
                  setLoading(false);
                  setError("Network connection might be lost, ")
              }                    
          }
        });
      }
    }

    refreshSections();

    const refreshPeriods = () => {
      //console.log.apply(propsUser);
      if(currentTerm){
        setLoading(true);
        axios.get(edusim.api_base_uri+"/api/instructors/periods/"+currentTerm._id,{
          headers: {
            'x-access-token': propsUser.token
          }
        }).then(res => {
          console.log("periods", res);
          setTermPeriods(res.data);
          setLoading(false);
        }).catch(e => {
          if(e.response){
              if(isMounted.current){
                  setLoading(false);
              }
              if(e.response.status === 403){
                  propsUpdateUser({});
              }
          }else{
              if(isMounted.current){
                  setLoading(false);
                  setError("Network connection might be lost, ")
              }                    
          }
        });
      }
    }

    props.scheduleView&&refreshPeriods();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [propsUpdateUser, propsUser, currentTerm]);

  React.useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) {
        if (error !== '') {
          setError('');
        }
      }
    }, 3000);
  }, [error]);

  const isInSectionSchedule = React.useCallback((section, day, period) => {
    let index = termPeriods.findIndex(o => o.day === day && o.period === period && o._groupid._id===section._groupid._id && o._subjectid._id===section._subjectid._id);
    return index >= 0 ? true : false;
  },[termPeriods]);

  const nextTerm = () => {
    let currentIndex = activeTerms.findIndex(e=>e._id===currentTerm._id);
    if(currentIndex >=0 && currentIndex+1 < activeTerms.length){
      setCurrentTerm(activeTerms[currentIndex+1]);
    }
    //currentTermId + 1 < 5 ? setCurrentTermId(currentTermId + 1) : setCurrentTermId(currentTermId);
  }

  const prevTerm = () => {
    let currentIndex = activeTerms.findIndex(e=>e._id===currentTerm._id);
    if(currentIndex >=0 && currentIndex-1 >=0){
      setCurrentTerm(activeTerms[currentIndex-1]);
    }
    //(currentTermId - 1 > 0) ? setCurrentTermId(currentTermId - 1) : setCurrentTermId(currentTermId);
  }

  const getLevelName = (_levelid) =>{
    let levelIndex = activeLevels.findIndex(e=>e._id===_levelid);
    return activeLevels[levelIndex]?activeLevels[levelIndex].name:'';
  }

  return (
    !props.user.active||!props.user.mobile?
      <Grid item xs={12}>
              <Alert style={{marginBottom: 10}} severity='error'>
                  Both your email and mobile must be verified to use this service
              </Alert>
            </Grid>
    :
    !propsUser._instructorid?
      <Grid item xs={12}>
              <Alert style={{marginBottom: 10}} severity='error'>
                  This service is only available to instructors. If you are an active instructor, contact your admin to be registered in the system.
              </Alert>
            </Grid>
    :

    <Container maxWidth="xl" className={classes.container}>
      {(error !== '') &&
        <Grid item xs={12}>
          <Alert style={{ marginBottom: 10 }} severity='error'>
            {error}
          </Alert>
        </Grid>
      }
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>
          <h2 style={{ padding: 0, margin: 0, flex: 1 }}>{props.scheduleView?"My Schedule":"My Sections"}</h2>
        </Box>
      </Box>

      <Grid container justify ='space-between'
        alignItems ="stretch" style={{marginTop: 10}}
        >
                          
        <Grid item>
          <Button
            disabled={activeTerms&&activeTerms.length<2}
            type="button"
            variant="contained"
            color="primary"
            onClick={() => prevTerm()}
          >
            Previous
          </Button>
        </Grid>
        <Grid item>
          <Typography component="h1" variant="h6" color ="primary">
            Term: {currentTerm && (currentTerm.name + " " + currentTerm.year)}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            disabled={activeTerms&&activeTerms.length<2}
            type="button"
            variant="contained"
            color="primary"
            onClick={() => nextTerm()}
          >
            Next
    </Button>
        </Grid>
      </Grid>

      {!props.scheduleView&&
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
            {(loading ? Array.from(new Array(3)) : sections).map((obj, key) => (
              <Grid key={key} item>
                {obj ? (
                  <Link to={'/sections/' + currentTerm._id + "/" + obj._id} style={{ textDecoration: 'none' }}>
                    <Card className={classes.cardRoot}>
                      <CardHeader style={{backgroundColor: obj.bgcolor?obj.bgcolor:"#ffffff"}}
                        avatar={
                          <Avatar aria-label="Section" className={classes.avatar}>
                            <ListAltSharpIcon />
                          </Avatar>
                        }
                        title={getLevelName(obj._groupid._levelid) + " " + obj._subjectid.name + " " + obj._groupid.name}
                        subheader={obj.name}
                      />
                      <CardMedia
                        component="img"
                        className={classes.media}
                        src="https://nowheretobefound.net/e.jpg"
                        onError={(e) => { e.target.onerror = null; e.target.src = sectionAvatarPlaceholder }}
                        title={obj._subjectid.name + obj._groupid.name}
                      />
                      <CardContent>


                      </CardContent>
                    </Card>
                  </Link>
                ) :
                  <Skeleton variant="rect" width={180} height={220} />
                }
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    }

    {props.scheduleView&&
      <Table>
      <TableHead>
          <TableRow >
              <TableCell><b>Days/Periods</b></TableCell>
              {periods.map((period, key) =>
              <TableCell style={{minWidth: 150, maxWidth: 150}} key={key}><b>P {period + 1}</b></TableCell>)}
          </TableRow>
      </TableHead>
      <TableBody>
          {days.map((day, key) =>
          <TableRow key={key}>
              <TableCell><b>Day {day + 1}</b></TableCell>
              {periods.map((period, key) =>(
                  <TableCell style={{minWidth: 150, maxWidth: 150}} key={key}>
                  {(loading ? Array.from(new Array(3)) : sections).map((obj) => (
                      (obj && isInSectionSchedule(obj, day, period) &&
                      <Link key={key} to={'/sections/' + currentTerm._id + "/" + obj._id} style={{ textDecoration: 'none' }}>
                      <Paper style={{padding: 5, backgroundColor: obj.bgcolor}} elevation={3}>
                          {getLevelName(obj._subjectid._levelid)}<br></br>{obj._subjectid.name}<br></br> {obj._groupid.name}
                      </Paper>
                      </Link>
                          )
                      
                  ))}
                  </TableCell>
              ))}
          </TableRow>
          )}  
      </TableBody>
      </Table>
    }
    </Container>

  );
}