import React from 'react';

import {
    Checkbox,
    Typography,
    Grid,
    Button,
    FormGroup,
    FormControlLabel,
    InputBase,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    makeStyles,
} from '@material-ui/core';

import axios from 'axios';
import edusim from '../config/edusim';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import maleAvatarPlaceholder from '../imgs/maleAvatarPlaceholder.png';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(0),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(0),
    },
    gridTable: {
        marginBottom: theme.spacing(3),
    }
}));

const StudentBadge = (props) =>{
    const student = props.student;
    return (
            <ListItem>
            <ListItemAvatar>
              <Avatar
                alt={student.name}
                src={maleAvatarPlaceholder}
              />
            </ListItemAvatar>
            <ListItemText primary={student.name} />
            
          </ListItem>
    )
}

const AttendanceView = (props) => {
    const [absences, setAbsences] = React.useState(props.selectedSession.absences?props.selectedSession.absences:[]);
    const [tardies, setTardies] = React.useState(props.selectedSession.tardies?props.selectedSession.tardies:{});
    const [tardyKey, setTardyKey] = React.useState("tardy");
    const section = props.section;
    const setAttendanceVisible = props.setAttendanceVisible;
    const selectedSession = props.selectedSession;
    const updateSessions = props.updateSessions;

    const propsUser = props.propsUser;
    const propsUpdateUser = props.propsUpdateUser;

    const isMounted = React.useRef(null);

    const [loading, setLoading] = React.useState(false);

    //error handling states
    const [error, setError] = React.useState('');

    const classes = useStyles();

    const isStudentAbsentInSession = (obj) =>{
        let foundIndex = absences?absences.findIndex(a=>a===obj._id):-1;

        return foundIndex >= 0;
    }

    const handleTardinessFocus = (e) => {
        e.target.setAttribute("data-old-value", e.target.value);      
    }

    const handleTardinessBlur = (e, studentid) => {
        if (e.target.getAttribute("data-old-value") !== e.target.value) {
            if(isNaN(e.target.value) || parseInt(e.target.value) < 0 || parseInt(e.target.value) > (selectedSession.duration)){
                e.target.value = e.target.getAttribute("data-old-value");
            }else{
                if(e.target.value.trim()!=="")
                    setTardies({...tardies, [studentid]: parseInt(e.target.value)});
                else{
                    let tempObj = {...tardies};
                    delete tempObj[studentid];
                    setTardies(tempObj);
                }
                    
            }
        }
    }

    const handleCheckBoxChange = (e, student) =>{
        let tempArr = [...absences];
        let foundIndex = absences?absences.findIndex(a=>a===student._id):-1;
        if(foundIndex>=0){
            tempArr.splice(foundIndex, 1);
            setAbsences(tempArr);
        }else{
            tempArr.push(student._id);
            setAbsences(tempArr);
            //remove tardy
            let tempTardies = {...tardies};
            delete tempTardies[student._id];
            setTardies(tempTardies);
            setTardyKey("tardy"+Date.now()+Math.random());
        }
    }

    const handleCloseClick = (e) => {
        setAttendanceVisible (false);
    }

    const handleSaveClick = (e) => {
      setLoading(true);
      axios.post(edusim.api_base_uri+"/api/instructors/sections/"+section._id+"/sessions/attendance/"+selectedSession._id, 
      {attendance: {tardies: tardies, absences: absences}}, {
        headers: {
          'x-access-token': propsUser.token
        }
      }).then(res => {
        console.log(res);
        let tempObj = {...selectedSession};
        tempObj.absences = absences;
        tempObj.tardies = tardies;
        updateSessions(tempObj);
        setLoading(false);
        setAttendanceVisible (false);
      }).catch(e => {
        if(e.response){
            if(isMounted.current){
                setLoading(false);
                setAttendanceVisible (false);
            }
            if(e.response.status === 403){
                propsUpdateUser({});
            }
        }else{
            if(isMounted.current){
                setLoading(false);
                setError("Network connection might be lost, ")
            }                    
        }
      });
    }

    return (
        <React.Fragment>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Attendance &amp; Tardiness
            </Typography>
            
            <Table className={classes.gridTable}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Student Name
                        </TableCell>
                        <TableCell>
                            Absent
                        </TableCell>

                        <TableCell>
                            Tardiness (Minutes)
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {section.students && section.students.map((obj, key) => (
                        <TableRow key={key}>
                            <TableCell>
                                <StudentBadge student={obj} />
                                
                            </TableCell>
                            <TableCell>
                                <FormGroup>
                                    <FormControlLabel control={
                                        <Checkbox  
                                            color="primary" 
                                            inputProps={{ 'aria-label': 'secondary checkbox' }} />} 
                                            label="Absent" 
                                            onChange={(e)=>handleCheckBoxChange(e,obj)}
                                            checked={isStudentAbsentInSession(obj)}
                                        />
                                    </FormGroup>
                            </TableCell>
                            <TableCell>
                                <InputBase
                                key={tardyKey+obj._id}
                                disabled={isStudentAbsentInSession(obj)} 
                                placeholder="Minutes Late" 
                                onFocus={(e)=>handleTardinessFocus(e)}
                                onBlur = {(e)=> handleTardinessBlur (e,obj._id)}
                                defaultValue = {tardies[obj._id]}
                                >
                                </InputBase>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Grid container spacing={3}
                direction="row"
                justify="flex-end"
                alignItems="flex-end">
                <Grid item>
                    <Button 
                        onClick={(e) => handleCloseClick (e)}>
                            Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button color = "primary" 
                        variant="contained" 
                        onClick={(e) => handleSaveClick (e)}>
                        Save Attendance
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
export default AttendanceView;